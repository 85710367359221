import { RootProviders } from "../../../App";
import { TextInput } from "../../../components/control.components/textInput.component";
import LogoImage from "../../styles/assets/Login/logo.png";
import {
  autocompleteClasses,
  Grid,
  Paper,
  TextField,
  Avatar,
  IconButton,
  Modal,
  Menu,
  MenuItem,
  Switch,
  ThemeProvider,
  Button,
  Container,
} from "@mui/material";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { makeStyles, styled } from "@mui/styles";
import { mainTheme } from "../../../styles/themes";
import AddIcon from "@mui/icons-material/Add";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  RouteProps,
  useMatch,
  Link,
  useNavigate,
} from "react-router-dom";
import { PageContainer } from "../../../components/generic.components/layout/pagelayout.component";
import { PageBackgroundTop } from "../../../components/generic.components/layout/pagebackgroundTop.component";
import { PageBackgroundBottom } from "../../../components/generic.components/layout/pagebackgroundBottom.component";

import { WidgetCard } from "../../../components/generic.components/widgetcard.component";
import { ReactComponent as InspectionIcon } from "../../styles/assets/icons/icon_inspections2.svg";
import React, { useState } from "react";
import { buttonClasses } from "@mui/material/Button";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { useUserState, useUserDispatch, UserActions } from "../../../contexts/user/user.context";
import { Dashboard, DateRange } from "@mui/icons-material";
import { EventsContainer } from "../events/events.container";
import { CreateModal } from "../modal/create.modal";
import { SingleEvent } from "../events/singleEvent.component";
import { fakeEvent1 } from "../events/singleEvent.component";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { DashboardMain } from "../events/dashboard.main.screen";
import plexislogo from "../../../styles/logo_plexis.png";
import chuck from "../../../styles/chuck-testAvi.png";
import { SearchMain } from "./search.main";


type SearchRenderProps = {};

export const SearchRender: React.FC<SearchRenderProps> = ({}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const userDispatch = useUserDispatch()
  const navigate = useNavigate()

  return (
    <Grid
      container
      direction="column"
      sx={{ width: "100%", height: "100%", backgroundColor: "#E0E0E0" }}
    >
      <PageContainer sx={{ backgroundColor: "#E0E0E0" }}>
        <Grid item sx={{ backgroundColor: "white" }}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            p={1.5}
          >
            <Grid item>
              <span>
                <img src={plexislogo} height="50px" width="160px" />
              </span>
              <span
                style={{
                  paddingLeft: "15px",
                  position: "relative",
                  bottom: "20px",
                  fontWeight: "bold",
                  color: "#EC1748",
                  fontSize: "22px",
                  fontFamily: mainTheme.fonts.primary.bold,
                }}
              >
                NOTIFICATION DASHBOARD
              </span>
            </Grid>
            <Grid item>
              <Grid container direction="row" display="flex">
                <Grid item alignItems="center" display="flex">
                  <span
                    style={{
                      fontFamily: mainTheme.fonts.primary.reg,
                      color: "#152239",
                      fontSize: "24px",
                    }}
                  >
                    Welcome back{" "}
                  </span>
                  {/* where the user's name will be displayed, hardcoded for now */}
                  <span
                    style={{
                      paddingLeft: "5px",
                      fontFamily: mainTheme.fonts.primary.bold,
                      color: "#EC1748",
                      fontSize: "24px",
                    }}
                  >
                    {/* user Name */}
                  </span>
                </Grid>
                {/* <Grid
                  item
                  alignItems="center"
                  display="flex"
                  sx={{ paddingLeft: "10px" }}
                >
                  <Avatar sx={{ height: 64, width: 64 }} src={chuck} />
                </Grid> */}
                <Grid item>
                  <IconButton
                    // sx={{ position: "relative", top: "5px" }}
                    id="basic-button"
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}
                  >
                    <ExpandMoreIcon
                      fontSize="large"
                      style={{ color: mainTheme.palette.primary.main }}
                    />
                  </IconButton>
                  <Menu id="basic-menu" anchorEl={anchorEl} open={open} onClose={handleClose}>
                    <MenuItem onClick={() => {
                      userDispatch({
                        type: UserActions.logout,
                      })
                      navigate('/login')
                    }}>Log Out</MenuItem>
                  </Menu>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item sx={{ backgroundColor: "#E0E0E0" }}>
          <SearchMain />
        </Grid>
      </PageContainer>
    </Grid>
  );
};
