import React from "react";
import { useEffect, useRef } from "react";
import "./App.css";
import { UserProvider, useUserState } from "./contexts/user/user.context";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  RouteProps,
  useMatch,
  NavLink,
  Outlet,
  useResolvedPath,
  useLocation,
} from "react-router-dom";
import { makeStyles, styled } from "@mui/styles";
import { RoutePaths } from "./common/routes";
import WebFont from "webfontloader";
import { LoginScreen } from "./screens/auth/login.screen";
import { ThemeProvider } from "@emotion/react";
import { mainTheme } from "./styles/themes";
import { RegisterScreen } from "./screens/auth/register.screen";
import { ForgotPasswordScreen } from "./screens/auth/forgotpassword.screen";
import { VerifyTokenScreen } from "./screens/auth/verifyToken.screen";
import { DashboardMain } from "./screens/dashboard/events/dashboard.main.screen";
import { PageContainer } from "./components/generic.components/layout/pagelayout.component";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { DashBoardRender } from "./screens/dashboard/events/dashboard.render";
import { HistoryRender } from "./screens/dashboard/history/history.render";
import { SearchRender } from "./screens/dashboard/search/search.render";
import { SearchProvider } from "./contexts/search/search.context";
import { HistoryProvider } from "./contexts/history/history.context";
import { StatsProvider } from "./contexts/stats/stats.context";

import chuck from "./styles/chuck-testAvi.png";

import {
  autocompleteClasses,
  Avatar,
  Grid,
  IconButton,
  Paper,
} from "@mui/material";
import plexislogo from "./styles/logo_plexis.png";
import { ApiProvider } from "./contexts/api/api.context";
import { getStoredItem } from "./contexts/common/localstorage";
import { StorageKeys } from "./contexts/common/storeapp.types";

WebFont.load({
  google: {
    families: ["Proxima Nova: 300, 400, 700"],
  },
});

export const RootProviders: React.FC = ({ children }) => {
  return (
    <ThemeProvider theme={mainTheme}>
      <StatsProvider>
        <ApiProvider>
          <UserProvider>
            <SearchProvider>
              <HistoryProvider>
                <Router>{children}</Router>
              </HistoryProvider>
            </SearchProvider>
          </UserProvider>
        </ApiProvider>
      </StatsProvider>
    </ThemeProvider>
  );
};

export const useAuth = () => {
  const stateToken = useUserState();
  if (stateToken.token) {
    return stateToken.token
  } else {
    let storageToken = getStoredItem(StorageKeys.token);
    return storageToken;
  }
};

const ProtectedRoutes: React.FC = () => {
  const isAuth = useAuth();
  return isAuth ? <Outlet /> : <LoginScreen />;
};

const App: React.FC = () => {
  return (
    <RootProviders>
      <Routes>
        {/* PROTECTED ROUTES */}
        <Route element={<ProtectedRoutes />}>
          <Route path={"search"} element={<SearchRender />} />
          <Route path={"history"} element={<HistoryRender />} />
        </Route>
        {/* PUBLIC ROUTES */}
        <Route path={"/"} element={<Navigate to={"login"} />} />
        <Route path={"login"} element={<LoginScreen />} />
        {/* <Route path={"search"} element={<SearchRender />} />
        <Route path={"history"} element={<HistoryRender />} /> */}
      </Routes>
      {/* } */}
    </RootProviders>
  );
};

const DashBoardWrapper: React.FC<{ children: any }> = ({ children }) => {
  const { user, token } = useUserState();
  const classes = useStyles();
  const navLinkRef = useRef<any>();
  const location = useLocation();

  return (
    <PageContainer>
      <Grid item>
        <Grid container direction="row" justifyContent="space-between" p={1.5}>
          <Grid item>
            <span>
              <img src={plexislogo} height="50px" width="160px" />
            </span>
            <span
              style={{
                paddingLeft: "15px",
                position: "relative",
                bottom: "20px",
                fontWeight: "bold",
                color: "#EC1748",
                fontSize: "22px",
                fontFamily: mainTheme.fonts.primary.bold,
              }}
            >
              CAMPAIGN DASHBOARD
            </span>
          </Grid>
          <Grid item>
            <Grid container direction="row" display="flex">
              <Grid item alignItems="center" display="flex">
                <span
                  style={{
                    fontFamily: mainTheme.fonts.primary.reg,
                    color: "#152239",
                    fontSize: "24px",
                  }}
                >
                  Welcome back{" "}
                </span>
                {/* where the user's name will be displayed, hardcoded for now */}
                <span
                  style={{
                    paddingLeft: "5px",
                    fontFamily: mainTheme.fonts.primary.bold,
                    color: "#EC1748",
                    fontSize: "24px",
                  }}
                >
                  Chuck
                </span>
              </Grid>
              <Grid
                item
                alignItems="center"
                display="flex"
                sx={{ paddingLeft: "10px" }}
              >
                <Avatar sx={{ height: 64, width: 64 }} src={chuck} />
              </Grid>
              <Grid item>
                <IconButton sx={{ position: "relative", top: "5px" }}>
                  <ExpandMoreIcon
                    fontSize="large"
                    style={{ color: mainTheme.palette.primary.main }}
                  />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>{children}</Grid>
    </PageContainer>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100vh",
    // backgroundColor:'gray',
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundImage: "url(/img/background.png)",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  loginContainer: {
    height: "500px",
    width: "300px",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#FFF",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "30px",
    // alignSelf:'center'
  },
  loginPage: {
    backgroundColor: "whitesmoke",
  },
  loginBackgroundGray: {
    backgroundColor: "#EAE8E8",
    // borderWidth:'1px',
    // borderColor:'#FAE8E8',
    height: "50%",
    margin: "10px",
    // marginTop:'-20px',
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  welcomeBackRow: {
    display: "flex",
    flexDirection: "column",
    flex: 2,
    justifyContent: "center",
    marginTop: "10px",
    fontFamily: mainTheme.fonts.primary.med,
  },
  emailPasswordRow: {
    display: "flex",
    flex: 2.5,
  },
  rememberMeRow: {
    display: "flex",
    flexDirection: "row",
    flex: 1,
    alignItems: "center",
    // margin: '10px',
    fontSize: "12px",
    // fontFamily: 'Proxima Nova',
    // fontStyle:'normal'
    // justifyContent:'flex-start'
  },
  welcomeBackText: {
    // fontFamily: theme.
    color: "#0c508d",
    fontSize: "23px",
    display: "flex",
    justifyContent: "center",
  },
  signInText: {
    fontFamily: "Proxima Nova",
    color: "#5A5A5A",
    fontSize: "14px",
    display: "flex",
    justifyContent: "center",
  },
  forgotPassword: {
    fontSize: "12px",
  },
  buttonRow: {
    display: "flex",
    flexDirection: "row",
    width: "90%",
    justifyContent: "space-between",
    alignItems: "space-between",
  },
  button: {
    borderRadius: "70px",
  },
  logo: {
    width: "90%",
    display: "flex",
    justifyContent: "center",
  },
  logoWrapper: {
    padding: "20px",
    // backgroundColor:'cyan'
  },
  sideBar: {
    // width:'98%',
    height: "100%",
    boxShadow: "1px 1px 5px -2px rgba(0,0,0,0.5)",
    backgroundColor: "#FFF",
    // WebkitBoxShadow: '5px 1px 10px -2px rgba(0,0,0,0.75)',
    // mozBoxShadow: '5px 1px 10px -2px rgba(0,0,0,0.75)'
  },
  listWrapper: {
    boxShadow: "1px 1px 20px -10px rgba(0,0,0,0.5)",
    // boxShadow: '0px -15px 10px -15px #111',
  },
  iconWrapper: {
    height: 35,
    width: 35,
    borderRadius: 100,
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 8px 12px #00000015",
    display: "flex",
  },
  icon: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    margin: "0 auto",
    height: "inherit",
  },
}));

export default App;
