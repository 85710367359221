import { IUserState, Token } from "./usercontext.type";
import { UserActions } from "./user.context";
// import { login } from '../../helpers/api';
import { Middleware } from "../common/enhancereducer.hook";
import { IAction } from "../common/storebase.types";
import {
  clearStoredItem,
  getStoredItem,
  setStoredItem,
} from "../common/localstorage";
import { StorageKeys } from "../common/storeapp.types";

import jwtdecode from "jwt-decode";

export const userLogin: Middleware<IUserState, IAction> =
  (state, dispatch) => (next) => (action) => {
    if (
      action.type === UserActions.login ||
      action.type === UserActions.loginSession
    ) {
      if (action.payload) {
        const token = action.payload;
        setStoredItem(
          StorageKeys.token,
          action.payload,
          action.type === UserActions.loginSession
        );
        const info = jwtdecode<Token>(token);
        if (info) {
          dispatch({ type: UserActions.user, payload: info.user });
          dispatch({ type: UserActions.token, payload: token });
        }
      }
    }
    return next(action);
  };

export const loginFromStorage: Middleware<IUserState, IAction> =
  (state, dispatch) => (next) => (action) => {
    if (action.type === UserActions.loginFromStorage) {
      const token: string = getStoredItem(StorageKeys.token);
      try {
        const info = jwtdecode<Token>(token);
        if((info as any).exp < Date.now() / 1000) {
          dispatch({ type: UserActions.logout });
          return false
        } else {
          dispatch({ type: UserActions.user, payload: info.user });
          dispatch({ type: UserActions.token, payload: token });
        }
      } catch (e) {
        dispatch({ type: UserActions.logout });
      }
    }
    return next(action);
  };

export const relog: Middleware<IUserState, IAction> =
  (state, dispatch) => (next) => (action) => {
    if (action.type === UserActions.relog) {
      if (action.payload) {
        const token = action.payload;
        let local = getStoredItem(StorageKeys.rememberMe) === "true";
        if (!local) {
          local = !!window.localStorage.getItem(StorageKeys.token);
        }
        clearStoredItem(StorageKeys.token);
        setStoredItem(StorageKeys.token, action.payload, !local);
        const info = jwtdecode<Token>(token);
        if (info) {
          dispatch({ type: UserActions.user, payload: info.user });
          dispatch({ type: UserActions.token, payload: token });
        }
      }
    }
    return next(action);
  };

export const userLogout: Middleware<IUserState, IAction> =
  (state, dispatch, deps) => (next) => (action) => {
    if (action.type === UserActions.logout) {
      try {
        clearStoredItem(StorageKeys.token);
        dispatch({ type: UserActions.user, payload: null });
        dispatch({ type: UserActions.token, payload: null });
      } catch (error) {
        console.log("Error removing stored token");
      }
    }

    return next(action);
  };

export const getStoredUser = () => {
  const token = getStoredItem(StorageKeys.token);
  if (token) {
    const info = jwtdecode<Token>(token);
    return info.user;
  }
  return null;
};
