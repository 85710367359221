import React from 'react';
import {autocompleteClasses, Grid, Paper, TextField} from '@mui/material';
import { makeStyles } from '@mui/styles'
import { TextFieldProps } from '@mui/material/TextField'
import { mainTheme } from '../../styles/themes';


type ITextInput = Omit<TextFieldProps, 'variant'>


export const TextInput: React.FC<ITextInput & {noBorderBottom?: Boolean, height?: string}> = ({noBorderBottom, height, ...props}) => {
    const classes = useStyles()
    return (
        // null
        <TextField
            // sx={{padding:'5px'}}
            className={`${classes.textInput} ${classes.root}`}
            sx={{borderBottom: noBorderBottom ? '0px solid #D6D6D6' : '1px solid #D6D6D6'}}
            {...props}
            InputProps={{
                style: {
                  fontSize:'14px',
                  height: height ?? ''
                },
                disableUnderline: true,
              }}
            InputLabelProps={{
            style: {
                    height: height ?? '',
                    fontSize:'14px',
                    color:mainTheme.palette.common.medGrey
                }
            }}
            variant='filled'
        />
    )
}

const useStyles = makeStyles((theme: any) => ({
    textInput: {
        width:'100%',
        backgroundColor:'#FFF',
        // borderWidth: '1px',
        // borderColor: '#D6D6D6',
        // padding:'5px'
    },
    root: {
        "& .MuiFilledInput-root": {
            background: "#FFF",
            borderTop:'1px solid #D6D6D6',
            borderLeft:'1px solid #D6D6D6',
            borderRight:'1px solid #D6D6D6',
            // borderBottom:'1px solid #D6D6D6',
            borderRadius:'0px',
        }
    },
    resize: {
        fontSize:'10px'
    },
    noBorder: {
        border:'0px'
    }
}))