import axios, { /* AxiosError, */ AxiosRequestConfig } from "axios";
import axiosRetry, { IAxiosRetryConfig } from "axios-retry";
import { Dispatch } from "react";
import reportWebVitals from "../../reportWebVitals";
import { getStoredItem } from "../common/localstorage";
import { StorageKeys } from "../common/storeapp.types";
import { IAction } from "../common/storebase.types";
import {
  APIRes,
  BaseRes,
  LoginErrorData,
  LoginReq,
  EventSearchReq,
  HistorySearchReq,
  ResetPasswordReq,
  WorkReq,
  SendNotificationReq,
  getStatsReq,
} from "./api.types";


const retryConfig: IAxiosRetryConfig = {};

const createAxios = () => {
  // UNCOMMENT THIS BEFORE GOING TO PRODUCTION, THIS IS ONLY FOR TESTING
  const token = getStoredItem(StorageKeys.token);

  
  const api = axios.create({
    // baseURL: process.env.WEBSITE_URL_LOCAL ?? 'http://3.131.69.152/' + process.env.REACT_APP_API,
    baseURL: `${process.env.REACT_APP_LOCAL}${process.env.REACT_APP_API}`,
    headers: {
      /* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */
      Authorization: `Bearer ${token}`,
    },
  });
  axiosRetry(api, retryConfig);
  return api;
};


const createRunner = () => {
  return async function runAxios<T, E = {}>(
    config: Partial<AxiosRequestConfig>
  ) {
    const axios = createAxios();
    if (!axios) return undefined;
    try {
      const res: APIRes<T, E> = await axios({
        ...config,
        method: config.method ?? "GET",
      });
      if (res) {
        return res
      } else {
        return undefined
      }
    } catch (e: any) {
      console.log(e);
      return e.response.data as BaseRes<T, E>;
    }
  };
};

// Just added this for the file download stream
const createRunnerRaw = () => {
  return async function runAxios<T, E = {}>(
    config: Partial<AxiosRequestConfig>
  ) {
    const axios = createAxios();
    if (!axios) return undefined;
    try {
      const res: APIRes<T, E> = await axios({
        ...config,
        method: config.method ?? "GET",
      });
      return res;
    } catch (e: any) {
      console.log(e);
      return e.response.data as BaseRes<T, E>;
    }
  };
};
// unauth

export const plexisLogin = async (data: LoginReq) => {
  let res = await axios.post(`${process.env.REACT_APP_LOCAL}${process.env.REACT_APP_API}/dashboard/login`, {
    password: data.password,
    userName: data.userName
  })
  return res
}



// export const resendCode = async (data: { email: string }) => await createRunner()<{ Message: string }>({ url: '/unauth/resend', data, method: 'POST' })

export const verifyCode = async (data: { token: string, code: number }) => await createRunner()<{ data: { token: string } }>({ url: '/user/verify-code', data, method: 'POST' })

export const changePassword = async (data: { token: string, newPassword: string }) => createRunner()<{ Success: boolean, Message: string }>({ url: '/user/change-password', data, method: 'POST'})

export const forgotPassword = async (data: { email: string }) =>
  await createRunner()({
    url: "/user/forgot-password",
    data,
    method: "POST",
  });









  


// export const toggleRole = async () => await createRunner()<{ token: string }>({ url: '/user/me/toggle-role', method: 'PUT' })
// centerLatitude=${req.lat}&centerLongitude=${req.lng}&radius=${req.radius}
// user

export const createFuncs = (userDispatch: Dispatch<IAction>) => {
  const runAxios = createRunner(); // default false
  return {
    ////Plexis
    getStats: async (req: getStatsReq) => 
      await runAxios<{ data: any, Message: string}>({
        url: `/dashboard/stats?lat=${req.lat}&lng=${req.lng}&radius=${req.radius}`, 
      }),
    searchEvents: async (req: EventSearchReq) => {
      let url = (req.name && req.name != null || undefined ? `/dashboard/events?centerLatitude=${req.lat}&centerLongitude=${req.lng}&radius=${req.radius}&page=${req.page}&friendEventsOnly=0&size=25&start=${req.startDate}&end=${req.endDate}&name=${req.name}` : `/dashboard/events?centerLatitude=${req.lat}&centerLongitude=${req.lng}&radius=${req.radius}&page=${req.page}&friendEventsOnly=0&size=25&start=${req.startDate}&end=${req.endDate}`)
      return await runAxios<{ data: any, Message: string, Success: boolean}>({
        url: url,
        method: "GET"
      })
    },
    searchHistory: async (req: HistorySearchReq) => 
      await runAxios<{ data: any, Message: string}>({
        url: `/dashboard/history?centerLatitude=${req.lat}&centerLongitude=${req.lng}&radius=${req.radius}&name=${req.name}`,
      }),
    scheduleNotification: async (req: SendNotificationReq) => 
      await runAxios<{ data: any, Message: string}>({
        url: "/dashboard/schedule",
        data: req,
        method: "POST"
      }),
    
    sendNowNotification: async (req: SendNotificationReq) => 
      await runAxios<{ data: any, Message: string}>({
        url: "/dashboard/send",
        data: req,
        method: "POST"
      }),
    getAllStats: async () =>
      await runAxios<{ data: any, Message: string}>({
        url: `/dashboard/stats`,
        method: "GET"
      }),
     



    //USER
    // createWorkRequest: async (uploadObject: Models.CreateWorkRequest) => {
    //   const formData = new FormData();
    //   formData.append("file", uploadObject.file);
    //   formData.append("clientId", uploadObject.clientId);
    //   formData.append("inspectorId", uploadObject.inspectorId);
    //   formData.append("AssignedBy", uploadObject.assignedBy)
    //   formData.append("status", uploadObject.status.toString())
    //   formData.append('requestDate', uploadObject.requestDate.toISOString())
    //   formData.append('completedDate', uploadObject.completedDate.toISOString())
    //   formData.append('locationId', uploadObject.locationId)
    //   formData.append('levelType', uploadObject.levelType.toString())
    //   formData.append('requestType', uploadObject.requestType.toString())
    //   formData.append('contactInfo', uploadObject.contactInfo.toString())
    //   formData.append('siteInformation', uploadObject.siteInformation.toString())
    //   formData.append('switchingPractice', uploadObject.switchingPractice.toString())
      
      
    //   return await runAxios<{ Data: Models.WorkRequest; Message: string }>({
    //     url: "/work-request",
    //     data: formData,
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "multipart/form-data",
    //     },
    //   });
    // },
    
    getUserWithToken: async () =>
      await runAxios<{ data: any; Message: string }>({
        url: `/user/token`,
      }),
    getHomeData: async () =>
      await runAxios<{ data: any; Message: string }>({
        url: `/user/home`,
      }),
    getUser: async (id: string) =>
      await runAxios<{ data: any; Message: string }>({
        url: `/user/id/${id}`,
      }),

    // CLIENT
    getClient: async (id: string) =>
      await runAxios<{ data: any; Message: string }>({
        url: `/client/id/${id}`,
      }),

    ////Work Requests
    createWorkRequest: async (workRequest: any) =>
      await runAxios<{ data: any }>({
        url: '/work-request',
        data: workRequest,
        method: 'POST'
      }),
    uploadRequestDocument: async (workReqId: string, file: any) => {
      const formData = new FormData()
      formData.append("file", file);
      return await runAxios<{ data: any }>({
        url: `/work-request/add-document/${workReqId}`,
        data: formData,
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
        }
      })
    },
    addPhotos: async (workReqId: string, photos: any[]) => {
      
      const formData = new FormData()
      formData.append("photo1", photos[0])
      formData.append("photo2", photos[1])
      formData.append("photo3", photos[2])
      
      return await runAxios<{ data: any }>({
        url: `/work-request/add-photos/${workReqId}`,
        data: formData,
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
        }
      })
    
    },
    // DOCUMENTS
    
    // uploadDocument: async (uploadObject: Models.CreateDocumentRequest) => {
    //   const formData = new FormData();
    //   formData.append("file", uploadObject.file);
    //   formData.append("clientId", uploadObject.clientId);
    //   formData.append("createdAt", uploadObject.createdAt.toDateString());
    //   formData.append("locationId", uploadObject.locationId);
    //   formData.append("modifiedAt", uploadObject.modifiedAt.toDateString());
    //   formData.append("startDate", uploadObject.startDate.toDateString());
    //   formData.append("type", uploadObject.type.toString());

    //   return await runAxios<{ Data: Models.Document; Message: string }>({
    //     url: "/document",
    //     data: formData,
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "multipart/form-data",
    //     },
    //   });
    // },
    documentDownload: async (docID: string) =>
      await createRunnerRaw()<{ data: any }>({
        url: `/document/file/${docID}`,
        responseType: "arraybuffer",
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/pdf'
          //   "Access-Control-Allow-Origin": "*",
          //   "Content-Type": "application/json",
          //   "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
          // "Access-Control-Allow-Headers": "authorization",
          // "Access-Control-Allow-Methods": "GET,HEAD,PUT,PATCH,POST,DELETE",
          // "Access-Control-Allow-Origin": "*"
        },
      }),

    // claimFFL: async (req: ClaimFFLReq) => {
    //   const { fflNumber, ...data } = req
    //   return await runAxios({ url: `/ffl/claim/${fflNumber}`, data, method: 'POST' })
    // },

    // // User
    // getSettings: async () => await runAxios<{ Data: Models.User }>({ url: '/ffl/settings' }),
    // updateUser: async (userId: string, updates: Partial<Models.User>) => await runAxios<UserUpdate>({ url: `/user/${userId}/edit`, method: 'PUT', data: { newUser: updates } }),
    // toggleUserBan: async (userId: string, bannedReason: string) => await runAxios<UserUpdate>({ url: `/user/${userId}/toggle-ban`, method: 'PUT', data: { bannedReason } }),
    // unfollowFFLs: async (unfollows: string[]) => await runAxios<{ Data: Models.User }>({ url: '/ffl/bulk-unfollow', method: 'DELETE', data: { unfollows } }),

    // // FFL
    // getAllFFLs: async () => await runAxios<{ Data: Models.FFLPage[] }>({ url: '/ffl', method: 'GET' }),
    // approveFFL: async (pageId: string) => await runAxios<{ Data: any }>({ url: `/ffl/approve/${pageId}`, method: 'PUT' }),
    // deleteLicense: async (licenseId: string) => await runAxios<{ Data: any }>({ url: `/ffl/${licenseId}`, method: 'DELETE' }),
    // uploadLicenses: async (upload: File) => {
    //   const formData = new FormData()
    //   formData.append('license', upload)
    //   return await runAxios<{ Data: any }>({
    //     url: '/ffl/license',
    //     data: formData,
    //     method: 'POST',
    //     headers: {
    //       'Content-Type': 'multipart/form-data'
    //     }
    //   })
    // },
    // // uploadLicenses: async (file: any) => await runAxios<{ Data: any }>({ url: '/ffl/license', data: { license: file }, method: 'POST' }),

    // getFFL: async (fflIdentifier: string) => await runAxios<{ Data: GetFFLResponse }>({ url: `/ffl/${fflIdentifier}` }),
    // getFFLPosts: async (fflIdentifier: string, page: number) => await runAxios<{ Data: GetFFLPosts }>({ url: `/ffl-post/${fflIdentifier}/ffl/${page}/page` }),
    // upload: async (upload: File) => {
    //   const formData = new FormData()
    //   formData.append('upload', upload)
    //   return await runAxios<{ Data: Models.MediaContent }>({
    //     url: '/upload',
    //     data: formData,
    //     method: 'POST',
    //     headers: {
    //       'Content-Type': 'multipart/form-data'
    //     }
    //   })
    // },
    // uploadVideo: async (upload: File) => {
    //   const formData = new FormData()
    //   formData.append('upload', upload)
    //   return await runAxios<{ Data: Models.MediaContent }>({
    //     url: '/upload/video',
    //     data: formData,
    //     method: 'POST',
    //     headers: {
    //       'Content-Type': 'multipart/form-data'
    //     }
    //   })
    // },
    // uploadPDF: async (upload: File) => {
    //   const formData = new FormData()
    //   formData.append('upload', upload)
    //   return await runAxios<{ Data: Models.MediaContent }>({
    //     url: '/upload/document',
    //     data: formData,
    //     method: 'POST',
    //     headers: {
    //       'Content-Type': 'multipart/form-data'
    //     }
    //   })
    // },
    // getMedia: async () => await runAxios<{ Data: Models.MediaContent[] }>({ url: '/upload/user' }),
    // getFFLMedia: async (fflPageId: string) => await runAxios<{ Data: Models.MediaContent[] }>({ url: `/upload/ffl/${fflPageId}` }),
    // updatePage: async (fflIdentifier: string, updates: Partial<Models.FFLPage>) => {
    //   const { headerImage, profileImage, ...page } = updates
    //   return await runAxios<{ Data: Models.FFLPage }>({ url: `/ffl/${fflIdentifier}`, data: { page }, method: 'PUT' })
    // },

    // follow: async (fflIdentifier: string) => await runAxios<{ Data: Models.User }>({ url: `/ffl/follow/${fflIdentifier}`, method: 'POST' }),
    // unFollow: async (fflIdentifier: string) => await runAxios<{ Data: Models.User }>({ url: `/ffl/unfollow/${fflIdentifier}`, method: 'DELETE' }),
    // setHome: async (fflIdentifier: string) => await runAxios<{ Data: Models.User }>({ url: `/ffl/home/${fflIdentifier}`, method: 'PUT' }),
    // clearHome: async () => await runAxios<{ Data: Models.User }>({ url: '/ffl/clear-home', method: 'PUT' }),

    // // Posts
    // getPost: async (postId: string) => await runAxios<{ Data: FFLPostData }>({ url: `/ffl-post/${postId}/post` }),
    // getFeed: async (page: number) => await runAxios<{ Data: GetFFLPosts }>({ url: `/ffl-post/${page}/page` }),
    // createPost: async (content: Partial<Models.FFLPost>) => await runAxios<{ Data: Models.FFLPost }>({ url: '/ffl-post', data: { content }, method: 'POST' }),
    // likePost: async (postId: string) => await runAxios<{ Data: { likeCount: number, like: Models.PostLikes } }>({ url: `/ffl-post/${postId}/like`, method: 'POST' }),
    // unlike: async (likeId: string) => await runAxios<{ Data: { likeCount: number } }>({ url: `/ffl-post/${likeId}/unlike`, method: 'DELETE' }),
    // getFFLFollowers: async () => await runAxios<{ Data: Models.User[] }>({ url: '/ffl/follows', method: 'GET' }),

    // comment: async (postId: string, comment: string, tags: Array<Partial<Models.CommentTags>>) => await runAxios<{ Data: FFLPostData }>({ url: `/ffl-post/${postId}/comment`, data: { comment, tags }, method: 'POST' }),
    // reply: async (postId: string, commentId: string, comment: string) =>
    //   await runAxios<{ Data: FFLPostData }>({ url: `/ffl-post/${postId}/comment/${commentId}/reply`, data: { comment }, method: 'POST' }),
    // toggleGoing: async (postId: string) => await runAxios<{ Data: PostGoing }>({ url: `/ffl-post/${postId}/going`, method: 'POST' }),
    // toggleInterested: async (postId: string) => await runAxios<{ Data: PostInterested }>({ url: `/ffl-post/${postId}/interested`, method: 'POST' }),
    // likeComment: async (commentId: string) => await runAxios<{ Data: LikeResponse }>({ url: `/ffl-post/${commentId}/like/comment`, method: 'POST' }),
    // replyToComment: async (postId: string, commentId: string, comment: string) =>
    //   await runAxios<{ Data: Models.PostComments }>({ url: `/ffl-post/${postId}/comment/${commentId}/reply`, data: { comment }, method: 'POST' }),

    // submitHelpRequest: async (help: Partial<Models.HelpRequest>) => await runAxios<{ Data: Models.HelpRequest }>({ url: '/help-request', data: { help }, method: 'POST' }),
    // blockUser: async (block: Partial<Models.Blocks>) => await runAxios<{ Data: { newBlock: Models.Blocks, blocks: Models.Blocks[] } }>({ url: `/block/${block._blockee}`, data: { reason: block.reason }, method: 'POST' }),
    // submitIncident: async (incident: Partial<Models.Incident>) => await runAxios<{ Data: Models.Incident }>({ url: '/incident', data: { incident }, method: 'POST' }),
    // getNotifications: async () => await runAxios<{ Data: Models.Notification[] }>({ url: '/notif' }),
    // deleteNotifications: async (notifIds: string[]) => await runAxios<{ Data: Models.Notification[] }>({ url: '/notif', data: { notifIds }, method: 'DELETE' }),

    // // Messages
    // getMessages: async () => await runAxios<{ Data: Models.Message[] }>({ url: '/message' }),
    // createNewMessage: async (message: any) => await runAxios<{ Data: Models.Message[] }>({ url: '/message', data: { message }, method: 'POST' }),

    // // Admin
    // getAllUsers: async () => await runAxios<{ Data: Models.User[] }>({ url: '/user', method: 'GET' }),
    // getOEMUsers: async () => await runAxios<{ Data: Models.User[] }>({ url: '/user/OEM/role', method: 'GET' }),
    // inviteOEMAdmin: async (user: Partial<Models.User>) => await runAxios<{}>({ url: '/user/invite', method: 'POST', data: { user } }),
    // cancelOEMAdminInvite: async (userId: string) => await runAxios<{}>({ url: `/user/${userId}/invite`, method: 'DELETE' }),
    // oemNotify: async () => await runAxios<{ token: string }>({ url: '/user/settings-notify', method: 'POST' }),
    // getAllHelpDesk: async () => await runAxios<{ token: string }>({ url: '/help-request', method: 'GET' }),
    // resolveHelpDesk: async (helpId: string) => await runAxios<{ token: string }>({ url: `/help-request/${helpId}`, method: 'PUT' }),
    // getAllIncidents: async () => await runAxios<{ token: string }>({ url: '/incident', method: 'GET' }),
    // resolveIncident: async (incidentId: string) => await runAxios<{ token: string }>({ url: `/incident/${incidentId}`, method: 'PUT' }),
    // getPlatformNotifications: async () => await runAxios<{ token: string }>({ url: '/platform-notif', method: 'GET' }),
    // getActivePlatformNotifications: async () => await runAxios<{ token: string }>({ url: '/platform-notif/active', method: 'GET' }),
    // createPlatformNotification: async (notif: any) => await runAxios<{ token: string }>({ url: '/platform-notif', data: { notif }, method: 'POST' }),
    // updatePlatformNotification: async (notifId: string, notif: any) => await runAxios<{ token: string }>({ url: `/platform-notif/${notifId}`, data: { notif }, method: 'PUT' }),
    // deletePlatformNotification: async (notifId: string) => await runAxios<{ token: string }>({ url: `/platform-notif/${notifId}`, method: 'DELETE' }),
    // disableCommenting: async (postId: string) => await runAxios<{ token: string }>({ url: `/ffl-post/${postId}/disable-comments`, method: 'PUT' }),
    // hidePost: async (postId: string) => await runAxios<{ token: string }>({ url: `/ffl-post/${postId}/hide`, method: 'PUT' }),
    // getAllPromos: async () => await runAxios<{ Data: Models.ManufacturerPromo }>({ url: '/oem-promo', method: 'GET' }),
    // createPromo: async (content: Partial<Models.ManufacturerPromo>) => await runAxios<{ Data: any }>({ url: '/oem-promo', data: { content }, method: 'POST' }),
    // updatePromo: async (promoId: string, startDate: Date, endDate: Date, message: string) => await runAxios<{ Data: any }>({ url: `/oem-promo/${promoId}`, data: { startDate, endDate, message }, method: 'PUT' }),
    // approvePromo: async (promoId: string) => await runAxios<{ Data: any }>({ url: `/oem-promo/${promoId}/approve`, method: 'PUT' }),
    // getAdminStats: async () => await runAxios<{ Data: any }>({ url: '/ffl/admin-stats', method: 'GET' }),

    // deactivateAccount: async (userId: string) => await runAxios<{}>({ url: `/user/${userId}/deactivate`, method: 'PUT' }),
    // changePassword: async (pass: object) => await runAxios<{}>({ url: '/user/reset-password', data: pass, method: 'POST' }),
    // searchFFL: async (searchString?: string, stateString?: string, lat?: number, long?: number, radius?: number, zip?: string) => await runAxios<{ Data: Models.FFLLicense[] }>({ url: '/ffl/search', data: { searchString, stateString: stateString, lat, long, radius, zip }, method: 'POST' }),
    // searchFFLsNearMe: async (page?: number, lat?: number, long?: number) => await runAxios<{ Data: any }>({ url: `/map/${page}`, data: { lat, long }, method: 'POST' }),
    // searchFFLsByLocationName: async (page?: number, location?: string, name?: string) => await runAxios<{ Data: any }>({ url: `/map/${page}/search`, data: { location, name }, method: 'POST' }),
    // updatePromoMetric: async (promoId: string, metric: string) => await runAxios<{ Data: any }>({ url: `/oem-promo/${promoId}/metric`, data: { metric }, method: 'PUT' })
  };
};
