import React from "react";
import { useState, useEffect } from "react";
import {
  Grid,
  Tabs,
  Tab,
  TextField,
  Button,
  Select,
  MenuItem,
  OutlinedInput,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { makeStyles } from "@mui/styles";
import { mainTheme } from "../../../styles/themes";
import { PageContainer } from "../../../components/generic.components/layout/pagelayout.component";
import { useMatch, useLocation, useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import { getPointsFromZip } from "../geocode/geocode";
import { convert, convertMany } from "convert";
import { useFormik } from "formik";
import * as yup from "yup";
import Geocode from "react-geocode";
import { useApi } from "../../../contexts/api/api.context";
import { isFuture } from "date-fns";
import {
  HistoryActions,
  useHistoryDispatch,
  useHistoryState
} from "../../../contexts/history/history.context";
import { useStatsDispatch, useStatsState, StatsActions } from "../../../contexts/stats/stats.context";
import { date } from "yup/lib/locale";

type HistoryEventsContainerProps = {};

export const HistoryEventsContainer: React.FC<
  HistoryEventsContainerProps
> = ({}) => {
  const classes = useStyles();
  const [error, setError] = useState("");
  const [zipcode, setZipCode] = useState<string>("");
  const [radius, setRadius] = useState<number>(50);
  const [name, setName] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const [path, setPath] = useState(location.pathname);
  const navigate = useNavigate();
  const historyDispatch = useHistoryDispatch();
  const { lat, lng, page} = useHistoryState()
  const [startDate, setStartDate] = useState("")
  const [startDateError, setStartDateError] = useState(false)
  const [endDateError, setEndDateError] = useState(false)
  const [endDate, setEndDate] = useState("")
  const [empty, setEmpty] = useState(false)
  const { searchHistory } = useApi()
  const statsDispatch = useStatsDispatch()
  const { getStats, getAllStats } = useApi()

  const handleTabsChange = (e: React.SyntheticEvent, path: string) => {
    navigate(path);
  };

  useEffect(() => {
    getAll()
  }, [])

  useEffect(() => {
    historyDispatch({
      type: HistoryActions.radius,
      payload: radius
    });
    if (zipcode.length === 5) {
      dispatchLatLong()
    }
  }, [zipcode])

  const getAll = async () => {
    let res = await getAllStats();
    if (res?.data) {
      statsDispatch({ type: StatsActions.totalRead, payload: res.data.numRead});
      statsDispatch({ type: StatsActions.totalSent, payload: res.data.numSent});
    }
  }

  const dispatchLatLong = () => {
    Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY!);
    Geocode.setLocationType("ROOFTOP");
    Geocode.fromAddress(zipcode)
      .then((response) => {
        const { lat, lng } = response.results[0].geometry.location;
        historyDispatch({ type: HistoryActions.lat, payload: lat });
        historyDispatch({ type: HistoryActions.lng, payload: lng });
      })
      .catch((error) => {
        setError(error);
      });
  };

  

  const handleRadiusChange = (e: any) => {
    setRadius(parseInt(e.target.value));
    historyDispatch({
      type: HistoryActions.radius,
      payload: parseInt(e.target.value),
    });
  };

  const handleSubmit = async () => {
    setLoading(true);
    let area = await getStats({
      radius: convert(radius, "miles").to("meters"),
      lat: lat!,
      lng: lng!,
    });
    statsDispatch({
      type: StatsActions.areaRead,
      payload: area?.data.numRead,
    });
    statsDispatch({
      type: StatsActions.areaSent,
      payload: area?.data.numSent,
    });
    if (name && name.length > 3) {
      let res = await searchHistory({
        radius: convert(radius, "miles").to("meters"),
        lat: lat!,
        lng: lng!,
        startDate: startDate,
        endDate: endDate,
        page: page,
        name: name,
      });
      if (res) {
        if (res.data.events.empty === true) {
          setEmpty(true)
        } else {
          historyDispatch({
            type: HistoryActions.totalNumberOfEvents,
            payload: res.data.events.totalElements
          })
          historyDispatch({
            type: HistoryActions.events,
            payload: res.data.events.content
          })
          
        }
      } else {
        setError('Something went wrong');
      }
    } else {
      let area = await getStats({
        radius: convert(radius, "miles").to("meters"),
        lat: lat!,
        lng: lng!,
      });
      statsDispatch({
        type: StatsActions.areaRead,
        payload: area?.data.numRead,
      });
      statsDispatch({
        type: StatsActions.areaSent,
        payload: area?.data.numSent,
      });
      let res = await searchHistory({
        radius: convert(radius, "miles").to("meters"),
        lat: lat!,
        lng: lng!,
        startDate: startDate,
        endDate: endDate,
        page: page,
      });
      if (res) {
        if (res.data.events.length < 1) {
          setEmpty(true)
        } else {
          historyDispatch({
            type: HistoryActions.totalNumberOfEvents,
            payload: res.data.events.totalElements
          })
          historyDispatch({
            type: HistoryActions.events,
            payload: res.data.events.content
          })
        }
      } else {
        setError('Something went wrong')
      }
    }
    setLoading(false);
  };

  const handleStartDateChange = (e: any) => {
    if (isFuture(new Date(e.target.value)) === true) {
      let date = encodeURI(e.target.value + ':00')
      setStartDate(date)
      setStartDateError(false)
      console.log(startDate)
      historyDispatch({
        type: HistoryActions.startDate,
        payload: date
      })
    } else {
      setStartDateError(true)
      console.log('error set')
    }
    
  }

  const handleEndDateChange = (e: any) => {
    if (isFuture(new Date(e.target.value)) === true) {
      let date = encodeURI(e.target.value + ':00')
      setEndDate(date)
      setEndDateError(false)
      console.log(endDate)
      historyDispatch({
        type: HistoryActions.endDate,
        payload: date
      })
    } else {
      setEndDateError(true)
      console.log('error set')
    }
  }


  

  const handleNameChange = (e: any) => {
    setName(e.target.value);
    historyDispatch({ type: HistoryActions.name, payload: e.target.value });
  };

  return (
    <PageContainer className={classes.page}>
      <Grid item display="flex" sx={{ width: "100%" }}>
        <Grid container direction="row" display="flex" spacing={10}>
          <Grid item>
            <Tabs
              onChange={handleTabsChange}
              value={path}
              textColor="primary"
              indicatorColor="primary"
            >
              <Tab value={"/search"} disableRipple label="Search" />
              <Tab value={"/history"} disableRipple label="History" />
            </Tabs>
          </Grid>
          <Grid item display="flex" alignItems="center">
            <Grid
              container
              direction="row"
              display="flex"
              alignItems="center"
              spacing={2}
            >
              <Grid item>
                <Grid
                  container
                  direction="row"
                  display="flex"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item>
                    <div
                      style={{
                        fontFamily: mainTheme.fonts.primary.bold,
                        color: "#5A5A5A",
                        fontSize: "20px",
                      }}
                    >
                      Enter Search Zipcode
                    </div>
                  </Grid>
                  <Grid item>
                    <TextField
                      size="small"
                      value={zipcode}
                      inputProps={{ maxLength: 5, pattern: "[0-9]*" }}
                      onChange={(e) => {
                        setZipCode(e.target.value);
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid
                  container
                  direction="row"
                  display="flex"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item>
                    <div
                      style={{
                        fontFamily: mainTheme.fonts.primary.bold,
                        color: "#5A5A5A",
                        fontSize: "20px",
                      }}
                    >
                      Select Search Radius
                    </div>
                  </Grid>
                  <Grid item sx={{ paddingRight: "40px" }}>
                    <Select size="small" sx={{ width: "150px" }} value={radius} onChange={handleRadiusChange}>
                      <MenuItem value={25}>25</MenuItem>
                      <MenuItem value={50}>50</MenuItem>
                      <MenuItem value={100}>100</MenuItem>
                      <MenuItem value={150}>150</MenuItem>
                      <MenuItem value={200}>200</MenuItem>
                    </Select>
                  </Grid>
                  <Grid item>
                    <LoadingButton
                      color="secondary"
                      variant="contained"
                      loadingPosition="start"
                      disabled={zipcode.length != 5 ? true : false}
                      loading={loading}
                      startIcon={<SearchIcon />}
                      sx={{ borderRadius: "42px", width: "225px" }}
                      onClick={handleSubmit}
                    >
                      Search
                    </LoadingButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item display="flex" width={"100%"} sx={{ paddingTop: "40px", paddingBottom: 3 }}>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent='center'
          display="flex"
          spacing={3}
        >
          <Grid item>
            <div
              style={{
                fontFamily: mainTheme.fonts.primary.bold,
                color: "#5A5A5A",
                fontSize: "20px",
              }}
            >
              Start Date
            </div>
          </Grid>
          <Grid item>
            <OutlinedInput type="datetime-local" size="small" onChange={handleStartDateChange} />
          </Grid>
          <Grid item>
            <div
              style={{
                fontFamily: mainTheme.fonts.primary.bold,
                color: "#5A5A5A",
                fontSize: "20px",
              }}
            >
              End Date
            </div>
          </Grid>
          <Grid item>
            <OutlinedInput type="datetime-local" size="small" onChange={handleEndDateChange}/>
          </Grid>
          <Grid item>
            <div
              style={{
                fontFamily: mainTheme.fonts.primary.bold,
                color: "#5A5A5A",
                fontSize: "20px",
              }}
            >
              Event Name
            </div>
          </Grid>
          <Grid item sx={{ paddingRight: "30px" }}>
            <OutlinedInput size="small" type="search" sx={{ width: "250px" }} value={name} onChange={handleNameChange}/>
          </Grid>
        </Grid>
      </Grid>
      {empty === true ? 
        <Grid item width='100%' display='flex' justifyContent='center' sx={{ paddingTop: '15px', paddingBottom: '5px'}}>
          <div style={{ fontSize: '18px', color: 'red', fontFamily: mainTheme.fonts.primary.bold}}>No History Available.</div>
        </Grid> : null}
      {/* This is where the headers will go */}
      {/* <Grid
        item
        display="flex"
        sx={{ paddingTop: "40px", width: "100%", paddingBottom: 1 }}
      >
        <Grid container direction="row" display="flex">
          <Grid item flex={5} display="flex">
            <div className={classes.headerText}>Client</div>
          </Grid>
          <Grid item flex={3} display="flex">
            <div className={classes.headerText}>Scheduled/Sent Date</div>
          </Grid>
          <Grid item flex={3} display="flex" justifyContent="center">
            <div className={classes.headerText}>Total Sent</div>
          </Grid>
          <Grid item flex={3} display="flex">
            <div className={classes.headerText}>Total Opened</div>
          </Grid>
          <Grid item flex={4} display="flex" justifyContent="center">
            <div className={classes.headerText}>View Details</div>
          </Grid>
        </Grid>
      </Grid> */}
    </PageContainer>
  );
};

const useStyles = makeStyles((theme) => ({
  page: {
    bgColor: "white",
    paddingLeft: "20px",
    paddingTop: "20px",
    paddingRight: "20px",
    display: "flex",
  },
  headerText: {
    fontFamily: mainTheme.fonts.primary.bold,
    fontSize: "20px",
    color: "black",
  },
}));
