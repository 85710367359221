import { Middleware } from './enhancereducer.hook'
import { IAction } from './storebase.types'

export const logger: (title: string) => Middleware<unknown, IAction> = (title: string) => (state /*, dispatch */) => (next) => (action) => {
  if (process.env.NODE_ENV === 'production') {
    return next(action)
  } else {
    console.group(`${title} ${action.type}${action.loadStep ? ` ${action.loadStep}` : ''}`)
    console.groupCollapsed('Previous State')
    console.log(state)
    console.groupEnd()
    console.groupCollapsed('Action')
    console.log(action)
    console.groupEnd()
    const newState = next(action)
    console.groupCollapsed('Next State')
    console.log(newState)
    console.groupEnd()
    console.groupEnd()
    return newState
  }
}
