import { RootProviders } from "../../../App";
import { TextInput } from "../../../components/control.components/textInput.component";
import LogoImage from "../../styles/assets/Login/logo.png";
import {
  autocompleteClasses,
  Grid,
  Paper,
  TextField,
  Modal,
  Switch,
  ThemeProvider,
  Pagination,
  Button,
  Container,
  Box,
  Skeleton,
} from "@mui/material";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { makeStyles, styled } from "@mui/styles";
import { mainTheme } from "../../../styles/themes";
import AddIcon from "@mui/icons-material/Add";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  RouteProps,
  useMatch,
  Link,
} from "react-router-dom";
import { PageContainer } from "../../../components/generic.components/layout/pagelayout.component";
import { PageBackgroundTop } from "../../../components/generic.components/layout/pagebackgroundTop.component";
import { PageBackgroundBottom } from "../../../components/generic.components/layout/pagebackgroundBottom.component";

import { WidgetCard } from "../../../components/generic.components/widgetcard.component";
import { ReactComponent as InspectionIcon } from "../../styles/assets/icons/icon_inspections2.svg";
import React, { useEffect, useState } from "react";
import { buttonClasses } from "@mui/material/Button";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { useUserState } from "../../../contexts/user/user.context";
import { DateRange } from "@mui/icons-material";
import { EventsContainer } from "../events/events.container";
import { SingleEvent } from "../events/singleEvent.component";
import { CreateModal } from "../modal/create.modal";
import { SearchEventsContainer } from "./search.eventsContainer";
import { SearchSingleEvent } from "./search.singleEvent";
import {
  SearchActions,
  useSearchDispatch,
  useSearchState,
} from "../../../contexts/search/search.context";
import {
  useStatsState,
  useStatsDispatch,
} from "../../../contexts/stats/stats.context";
import { useApi } from "../../../contexts/api/api.context";
import { convert } from 'convert'

export const greeting = () => {
  let hour = new Date().getHours();
  if (hour >= 0 && hour <= 12) {
    return "Good Morning";
  } else if (hour > 12 && hour <= 17) {
    return "Good Afternoon";
  } else {
    return "Good Evening";
  }
};

type SearchMainProps = {};

const TAKE_VALUE: number = 25

export const SearchMain: React.FC<SearchMainProps> = () => {
  const classes = useStyles();
  const [selection, setSelection] = useState("severity");
  const { user, token } = useUserState();
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [initialLoad, setInitialLoad] = useState(false);
  const [error, setError] = useState("");
  const [pageCount, setPageCount] = useState(0)
  const { events, page, radius, name, startDate, endDate, lat, lng, totalNumberOfEvents  } = useSearchState();
  const searchDispatch = useSearchDispatch()
  const { totalSent, totalRead, areaRead, areaSent,  } = useStatsState();
  const { searchEvents } = useApi()

  const buttonSx = {
    [`& .${buttonClasses.startIcon} > *:nth-of-type(1)`]: {
      fontSize: "55px",
      color: "black",
      backgroundColor: "white",
      borderRadius: "50%",
      marginRight: "10px",
    },
    borderRadius: "42px",
    height: "74px",
    width: "100%",
    display: "flex",
    justifyContent: "space-around",
    fontSize: "23px",
    paddingLeft: "10px",
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  

  const handlePageChange = async (e: React.ChangeEvent<unknown>, value: number) => {
    searchDispatch({
      type: SearchActions.page,
      payload: (value - 1)
    })
    setLoading(true)
    console.log(page)
    let start: string = startDate === null ? "" : startDate;
    let end: string = endDate === null ? "" : endDate;
    if (name && name.length > 5) {
      let res = await searchEvents({
        page: page,
        name: name,
        radius: convert(radius!, "miles").to("meters"),
        startDate: start,
        endDate: end,
        lat: lat!,
        lng: lng!
      })
      console.log(res)
      if (res?.data) {
        searchDispatch({
          type: SearchActions.events,
          payload: res.data.events.content,
        });
        searchDispatch({
          type: SearchActions.userCount,
          payload: res.data.userCount,
        });
      }
    } else {
      let res = await searchEvents({
        page: value - 1,
        radius: convert(radius!, "miles").to("meters"),
        startDate: startDate!,
        endDate: endDate!,
        lat: lat!,
        lng: lng!
      })
      if (res?.data) {
        searchDispatch({
          type: SearchActions.events,
          payload: res.data.events.content,
        });
        searchDispatch({
          type: SearchActions.userCount,
          payload: res.data.userCount,
        });
      }
    }
    setLoading(false)
  }

  return (
    <Grid
      container
      direction="column"
      className={classes.page}
      sx={{ paddingLeft: 4, paddingRight: 4, paddingTop: 2, paddingBottom: 2 }}
    >
      {/* Create/Add Modal took this out for now  9/23 */}
      {/* <Modal open={modalOpen}>
        <Box>
          <CreateModal handleClose={handleModalClose}/>
        </Box>
      </Modal> */}

      {/* This is where the create button will be and the Tickers */}
      <Grid item>
        <Grid
          container
          direction="row"
          alignItems="center"
          display="flex"
          justifyContent="center"
          spacing={2}
        >
          {/* Create Campaign Button that we decided to remove 9/23 */}
          {/* <Grid item display='flex' >
            <Button variant="contained" size="large" startIcon={<AddIcon />} onClick={() => { setModalOpen(true)}}  color="primary"  sx={buttonSx}>
              Create Campaign
            </Button>
          </Grid> */}
          <Grid item display="flex">
            <Grid container direction="row" alignItems="center">
              <Grid
                item
                sx={{
                  height: "120px",
                  width: "200px",
                  borderRadius: "54px",
                  backgroundColor: "white",
                  marginRight: "15px",
                }}
              >
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  sx={{
                    paddingTop: 4,
                    paddingBottom: 3,
                    paddingLeft: 3,
                    paddingRight: 3,
                  }}
                >
                  <Grid item>
                    <div
                      style={{
                        fontSize: "20px",
                        color: "#5A5A5A",
                        fontFamily: mainTheme.fonts.primary.bold,
                      }}
                    >
                      Total Sents
                    </div>

                    {/* This will be the number of total sent notifications */}
                    <div
                      style={{
                        fontSize: "32px",
                        color: "#EC1748",
                        fontFamily: mainTheme.fonts.primary.bold,
                      }}
                    >
                      {totalSent === null ? "--" : totalSent}
                    </div>
                  </Grid>
                  {/* Total Number of percentage open took this out for now 9/23 */}
                  {/* <Grid item sx={{ alignItems: 'center', backgroundColor: '#EC1748', borderRadius: '42px', marginLeft: '10px', position: 'relative', bottom: '4px' }}>
                    <Grid container direction='row' sx={{ paddingTop: 2.5, paddingLeft: 3, paddingRight: 3, paddingBottom: 1.5,}}>
                      <Grid item> 
                        <ArrowDownwardIcon fontSize="large" style={{ color: 'white', position: 'relative', bottom: '2px'}}/>
                      </Grid>
                      <Grid item sx={{ paddingLeft: '5px'}}>
                        <span style={{ color: 'white', fontSize: '24px', fontFamily: mainTheme.fonts.primary.bold}}>3.5%</span>
                      </Grid>
                    </Grid>
                  </Grid> */}
                </Grid>
              </Grid>
              <Grid
                item
                sx={{
                  height: "120px",
                  width: "200px",
                  borderRadius: "64px",
                  backgroundColor: "white",
                }}
              >
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  sx={{
                    paddingTop: 4,
                    paddingBottom: 3,
                    paddingLeft: 3,
                    paddingRight: 3,
                  }}
                >
                  <Grid item>
                    <div
                      style={{
                        fontSize: "20px",
                        color: "#5A5A5A",
                        fontFamily: mainTheme.fonts.primary.bold,
                      }}
                    >
                      Total Opens
                    </div>

                    {/* This will be the number of total sent notifications */}
                    <div
                      style={{
                        fontSize: "32px",
                        color: "#A2CA62",
                        fontWeight: "bold",
                        fontFamily: mainTheme.fonts.primary.bold,
                      }}
                    >
                      {totalRead === null ? "--" : totalRead}
                    </div>
                  </Grid>

                  {/* Total Percentage of number opened, removed for now 9/23 */}
                  {/* <Grid item sx={{ alignItems: 'center', backgroundColor: '#A2CA62', borderRadius: '42px', marginLeft: '10px', position: 'relative', bottom: '4px' }}>
                    <Grid container direction='row' sx={{ paddingTop: 2.5, paddingLeft: 3, paddingRight: 3, paddingBottom: 1.5,}}>
                      <Grid item> 
                        <ArrowUpwardIcon fontSize="large" style={{ color: 'white', position: 'relative', bottom: '2px'}}/>
                      </Grid>
                      <Grid item sx={{ paddingLeft: '5px'}}>
                        <span style={{ color: 'white', fontSize: '24px', fontFamily: mainTheme.fonts.primary.bold}}>3.5%</span>
                      </Grid>
                    </Grid>
                  </Grid> */}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item display="flex">
            <Grid container direction="row" alignItems="center">
              <Grid
                item
                sx={{
                  height: "120px",
                  width: "200px",
                  borderRadius: "54px",
                  backgroundColor: "white",
                  marginRight: "15px",
                }}
              >
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  sx={{
                    paddingTop: 4,
                    paddingBottom: 3,
                    paddingLeft: 3,
                    paddingRight: 3,
                  }}
                >
                  <Grid item>
                    <div
                      style={{
                        fontSize: "20px",
                        color: "#5A5A5A",
                        fontFamily: mainTheme.fonts.primary.bold,
                      }}
                    >
                      Area Sents
                    </div>

                    {/* This will be the number of total sent notifications */}
                    <div
                      style={{
                        fontSize: "32px",
                        color: "#EC1748",
                        fontFamily: mainTheme.fonts.primary.bold,
                      }}
                    >
                      {areaSent === null ? "--" : areaSent}
                    </div>
                  </Grid>
                  {/* Total Number of percentage open took this out for now 9/23 */}
                  {/* <Grid item sx={{ alignItems: 'center', backgroundColor: '#EC1748', borderRadius: '42px', marginLeft: '10px', position: 'relative', bottom: '4px' }}>
                    <Grid container direction='row' sx={{ paddingTop: 2.5, paddingLeft: 3, paddingRight: 3, paddingBottom: 1.5,}}>
                      <Grid item> 
                        <ArrowDownwardIcon fontSize="large" style={{ color: 'white', position: 'relative', bottom: '2px'}}/>
                      </Grid>
                      <Grid item sx={{ paddingLeft: '5px'}}>
                        <span style={{ color: 'white', fontSize: '24px', fontFamily: mainTheme.fonts.primary.bold}}>3.5%</span>
                      </Grid>
                    </Grid>
                  </Grid> */}
                </Grid>
              </Grid>
              <Grid
                item
                sx={{
                  height: "120px",
                  width: "200px",
                  borderRadius: "64px",
                  backgroundColor: "white",
                }}
              >
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  sx={{
                    paddingTop: 4,
                    paddingBottom: 3,
                    paddingLeft: 3,
                    paddingRight: 3,
                  }}
                >
                  <Grid item>
                    <div
                      style={{
                        fontSize: "20px",
                        color: "#5A5A5A",
                        fontFamily: mainTheme.fonts.primary.bold,
                      }}
                    >
                      Area Opens
                    </div>

                    {/* This will be the number of total sent notifications */}
                    <div
                      style={{
                        fontSize: "32px",
                        color: "#A2CA62",
                        fontWeight: "bold",
                        fontFamily: mainTheme.fonts.primary.bold,
                      }}
                    >
                      {areaRead === null ? "--" : areaRead}
                    </div>
                  </Grid>

                  {/* Total Percentage of number opened, removed for now 9/23 */}
                  {/* <Grid item sx={{ alignItems: 'center', backgroundColor: '#A2CA62', borderRadius: '42px', marginLeft: '10px', position: 'relative', bottom: '4px' }}>
                    <Grid container direction='row' sx={{ paddingTop: 2.5, paddingLeft: 3, paddingRight: 3, paddingBottom: 1.5,}}>
                      <Grid item> 
                        <ArrowUpwardIcon fontSize="large" style={{ color: 'white', position: 'relative', bottom: '2px'}}/>
                      </Grid>
                      <Grid item sx={{ paddingLeft: '5px'}}>
                        <span style={{ color: 'white', fontSize: '24px', fontFamily: mainTheme.fonts.primary.bold}}>3.5%</span>
                      </Grid>
                    </Grid>
                  </Grid> */}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* Filtering component */}
      <Grid
        item
        sx={{
          backgroundColor: "white",
          marginTop: 2,
          borderRadius: "8px",
          width: "100%",
        }}
      >
        <SearchEventsContainer />
      </Grid>
      <Grid
        item
        sx={{
          backgroundColor: "white",
          marginTop: 1,
          marginBottom: 1,
          borderRadius: "8px",
          width: "100%",
          padding: 2
        }}
        display='flex'
      >
          <Grid container direction="row" display="flex">
            <Grid item flex={5} display="flex">
              <div className={classes.headerText}>Client</div>
            </Grid>
            <Grid item flex={3} display="flex">
              <div className={classes.headerText}>Event Start/End Date</div>
            </Grid>

            <Grid item flex={3} display="flex" justifyContent="center">
              <div className={classes.headerText}>City/State</div>
            </Grid>
            <Grid item flex={4} display="flex" justifyContent="center">
              <div className={classes.headerText}>Create Notification</div>
            </Grid>
          </Grid>
      </Grid>
      {events != null || undefined ? (
        <>
          {events?.map((event) => (
            <Grid
              key={event.id}
              item
              sx={{
                backgroundColor: "white",
                marginTop: 1,
                width: "100%",
                borderRadius: "8px",
              }}
            >
              <SearchSingleEvent Event={event} />
            </Grid>
          ))}
        </>
      ) : (
        <></>
      )}
      <Grid item display={'flex'} justifyContent='center' sx={{ marginTop: 1}}>
        <Pagination color="secondary" onChange={handlePageChange} count={totalNumberOfEvents === null || undefined ? 1 : Math.ceil(totalNumberOfEvents / TAKE_VALUE)} page={page + 1} disabled={events == null ? true : false}/>
      </Grid>
    </Grid>
  );
};

{
  /* <Grid item sx={{ backgroundColor: 'white', marginTop: 1, width: '100%', borderRadius: '8px' }}>
        <SearchSingleEvent  />
       </Grid> */
}

const useStyles = makeStyles((theme) => ({
  page: {
    backgroundColor: "#E0E0E0",
    height: "100%",
    width: "100%",
  },
  headerText: {
    fontFamily: mainTheme.fonts.primary.bold,
    fontSize: "20px",
    color: "black",
  },
}));
