import { RootProviders } from "../../App";
import {
  autocompleteClasses,
  Grid,
  Paper,
  TextField,
  Switch,
  ThemeProvider,
  CircularProgress,
  Button,
  Container,
  Typography,
  Divider,
} from "@mui/material";
import { makeStyles, styled } from "@mui/styles";
import { mainTheme } from "../../styles/themes";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  RouteProps,
  useMatch,
  Link,
} from "react-router-dom";
import { PageContainer } from "../../components/generic.components/layout/pagelayout.component";
import { useReducer, useState } from "react";
import { plexisLogin } from "../../contexts/api/api.functions";
import { LoginReq } from "../../contexts/api/api.types";
import {
  UserActions,
  useUserDispatch,
  useUserState,
} from "../../contexts/user/user.context";
import { TextInput } from "../../components/control.components/textInput.component";
import { useNavigate } from "react-router-dom";
import { clearStoredItem, setStoredItem } from "../../contexts/common/localstorage";
import { StorageKeys } from "../../contexts/common/storeapp.types";
import { useApi } from "../../contexts/api/api.context";
import logo from "../../styles/logo_plexis.png"
import jwtDecode from "jwt-decode";

// interface LoginReq {
//     userName: string
//     password: string
// }

const loginReducer = (state: LoginReq, update: Partial<LoginReq>) => ({
  ...state,
  ...update
});

export const LoginScreen: React.FC = () => {
  const classes = useStyles();
  const [loginReq, updateLogin] = useReducer(loginReducer, {
    userName: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [remember, setRemember] = useState(false);
  const userDispatch = useUserDispatch();
  const userState = useUserState();
  const {getUser } = useApi()
  const { token } = useUserState()

  const navigate = useNavigate();

  
  const onSubmit = async () => {
    // evt.preventDefault()
    setLoading(true)
    await plexisLogin({ password: loginReq.password, userName: loginReq.userName})
      .then((Response) => {
        if (Response.status === 200) {
          console.log(Response.data.token)
          userDispatch({
            type: remember ? UserActions.login : UserActions.loginSession,
            payload: Response.data.token
          })
          setLoading(false)
          let info = jwtDecode(Response.data.token)
          console.log(info)
          navigate('/search')
        } else {
          setLoading(false);
          setError("Username and/or password may be incorrect.")
        }
      })
    
  };

  const handleRemember = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      console.log('checked or not?? ', checked)
    if (checked) {
      setStoredItem(StorageKeys.rememberMe, 'true', false)
    } else {
      clearStoredItem(StorageKeys.rememberMe)
    }
    setRemember(checked)
  }

  const updateUsername = (
    data: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    updateLogin({ userName: data.target.value });
  };

  const updatePassword = (
    data: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    updateLogin({ password: data.target.value });
  };

  return (
    <div>
      <PageContainer
        className={classes.page}
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        {/* <form onSubmit={onSubmit}> */}
        <Grid
          container
          direction="column"
          className={`${classes.root} ${classes.taller}`}
          alignItems="center"
          sx={{ bgcolor: "whitesmoke", borderRadius: "30px" }}
        >
          
          {/* <Grid item>
                        <img
                            src={LogoImage}
                            className={classes.logo}
                        />
                    </Grid> */}
          <Grid
            container
            style={{ flex: 1 }}
            justifyContent="center"
            alignContent="center"
            direction="column"
          >
            <img src={logo} />
            {/* <Grid item justifyContent='center' alignContent='center'> */}
            {/* <img width="230px" src={LogoImage} className={classes.logo} /> */}
            {/* </Grid> */}
          </Grid>
          <Grid
            item
            justifyContent="flex-end"
            alignContent="flex-end"
            style={{
              flex: 3,
              backgroundColor: "#EAE8E8",
              width: "100%",
              padding: "10px",
              paddingTop: "20px",
            }}
          >
            <Grid
              container
              direction="column"
              spacing={0}
              sx={{ padding: "5px" }}
            >
              <Grid item>
                <span className={classes.welcomeBackText}>Welcome Back</span>
              </Grid>
              <Grid item>
                <span className={classes.signInText}>
                  Sign into your account to continue.
                </span>
              </Grid>
            </Grid>

            <Grid container style={{ paddingTop: "20px" }}>
              <Grid item width="100%">
                <TextInput
                  noBorderBottom
                  id="filled-email-input"
                  label="Username"
                  type="email"
                  autoComplete="current-password"
                  size="medium"
                  name="email"
                  value={loginReq.userName}
                  onChange={updateUsername}
                  // sx={{border: '1px solid #D6D6D6'}}
                />
              </Grid>
              <Grid item width="100%">
                <TextInput
                  id="filled-password-input"
                  label="Password"
                  type="password"
                  autoComplete="current-password"
                  size="medium"
                  name="password"
                  value={loginReq.password}
                  onChange={updatePassword}
                  // sx={{border: '1px solid #D6D6D6'}}
                />
              </Grid>
            </Grid>
            {/* <Grid container flex={1} height="24%">
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                alignContent="center"
              >
                <Grid item >
                  <div className={classes.rememberMeRow}>
                    Remember me
                    <Switch value={remember} onChange={handleRemember} color="primary" size="small" />
                    
                  </div>
                </Grid>
              </Grid>
            </Grid> */}
          </Grid>
          {error ? <div className={classes.error}>Username and/or password may be incorrect.</div> : null}
          <Grid item style={{ flex: 1, width: "95%" }}>
            <Grid
              style={{ height: "100%", width: '100%' }}
              container
              justifyContent="center"
              alignItems="center"
            >
              {loading ? <CircularProgress /> :
              <Grid item >
                <Button
                  onClick={() => onSubmit()}
                  style={{
                    borderRadius: "35px",
                    borderWidth: "1px",
                    fontFamily: mainTheme.fonts.primary.med,
                    width: '150px'
                  }}
                  type="submit"
                  size="medium"
                  color="primary"
                  variant={"contained"}
                >
                  Sign In
                </Button>
              </Grid>}
            </Grid>
          </Grid>
          {/* <Grid item style={{ flex: 1, width: "100%" }}>
            <div style={{ height: "1px", backgroundColor: "#EAE8E8" }} />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              <Typography
                sx={{ color: mainTheme.palette.common.medGrey }}
                align="center"
                variant="caption"
              >
                By Signing up, you agree to our company's
                <br></br>
                <Typography
                  align="center"
                  sx={{
                    color: mainTheme.palette.common.darkGrey,
                    textDecorationLine: "underline",
                  }}
                  variant="caption"
                >
                  Terms Of Service
                </Typography>
                &nbsp;and&nbsp;
                <Typography
                  sx={{
                    color: mainTheme.palette.common.darkGrey,
                    textDecorationLine: "underline",
                  }}
                  variant="caption"
                >
                  Privacy Policy
                </Typography>
              </Typography>
            </div>
          </Grid> */}
        </Grid>
        {/* </form> */}
      </PageContainer>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100vh",
    // backgroundColor:'gray',
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundImage: "url(/img/background.png)",
    backgroundSize: "cover",
    //   backgroundRepeat:'no-repeat'
  },
  taller: {
    minHeight: "500px",
  },
  root: {
    height: "fit-content",
    width: "fit-content",
    position: "relative",
    backgroundColor: "white",
    boxShadow: "0px 3px 8px 0px rgba(0,0,0,0.2)",
    maxHeight: 500,
    [mainTheme.breakpoints.down(850)]: {
      marginTop: 10,
      marginBottom: 20,
    },
    maxWidth: "350px",
    padding: "10px",
  },
  page: {
    background: "url(/img/background.png)",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    [mainTheme.breakpoints.down(850)]: {
      overflow: "auto",
    },
    display: "flex",
    justifyContent: "center",
    // flex: 1
  },
  loginContainer: {
    height: "500px",
    width: "300px",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#EC1748",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "30px",
    // alignSelf:'center'
  },
  loginBackgroundGray: {
    backgroundColor: "#EAE8E8",
    // borderWidth:'1px',
    // borderColor:'#FAE8E8',
    height: "50%",
    margin: "10px",
    // marginTop:'-20px',
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  welcomeBackRow: {
    display: "flex",
    flexDirection: "column",
    flex: 2,
    justifyContent: "center",
    marginTop: "10px",
    fontFamily: mainTheme.fonts.primary.med,
  },
  emailPasswordRow: {
    display: "flex",
    flex: 2.5,
  },
  rememberMeRow: {
    //   display: 'flex',
    //   flexDirection: 'row',
    //   flex: 1,
    //   alignItems:'center',
    // margin: '10px'
    fontFamily: mainTheme.fonts.primary.reg,
    fontSize: "12px",
    color: mainTheme.palette.common.link,
    // fontFamily: 'Proxima Nova',
    // fontStyle:'normal'
    // justifyContent:'flex-start'
  },
  welcomeBackText: {
    fontFamily: mainTheme.fonts.primary.med,
    color: '#EC1748',
    fontSize: "23px",
    display: "flex",
    justifyContent: "center",
    paddingBottom: "5px",
  },
  signInText: {
    fontFamily: mainTheme.fonts.primary.med,
    color: "#5A5A5A",
    fontSize: "15px",
    display: "flex",
    justifyContent: "center",
  },
  forgotPassword: {
    fontSize: "12px",
    fontFamily: mainTheme.fonts.primary.reg,
    "& .hover": {
      // backgroundColor:'orange'
      fontSize: "30px",
    },
  },
  buttonRow: {
    display: "flex",
    flexDirection: "row",
    width: "90%",
    justifyContent: "space-between",
    alignItems: "space-between",
  },
  logo: {
    //   width:'330px',
    display: "flex",
    justifyContent: "center",
  },
  signupbutton: {
    textDecoration: "none",
  },
  button: {
    borderRadius: "70px",
    "&:hover": {
      backgroundColor: mainTheme.palette.primary.main,
      color: "#3c52b2",
    },
  },
  error: {
      fontFamily: mainTheme.fonts.primary.med,
      color: 'red',
      fontSize: 14,
      height:20,
      position:'relative',
      top:10
    //   marginTop: 15
  }
}));
