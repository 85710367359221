import { IStatsState } from "./stats.type";
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
    useContext,
    useRef,
    useEffect,
    Dispatch,
    useCallback,
  } from "react";
  import { IAction } from "../common/storebase.types";
  import { useEnhancedReducer } from "../common/enhancereducer.hook";
  import { logger } from "../common/logger";
  import { useApi } from "../api/api.context";


export enum StatsActions {
    totalSent = 'totalSent',
    totalRead = 'totalRead',
    areaSent = 'areaSent',
    areaRead = 'areaRead'
  }

  const initialState = {
    totalSent: null,
    totalRead: null,
    areaSent: null,
    areaRead: null
  };



  const reducer = (state: IStatsState, action: IAction): IStatsState => {
    const newState = { ...state };
    switch (action.type) {
      case StatsActions.totalSent:
        newState.totalSent = action.payload;
        break;
      case StatsActions.totalRead:
        newState.totalRead = action.payload;
        break;
      case StatsActions.areaSent:
        newState.areaSent = action.payload;
        break;
      case StatsActions.areaRead:
        newState.areaRead = action.payload;
        break;
      default:
        return state;
    }
  
    return newState;
  };


export const StatsStateContext: any = React.createContext({});
export const StatsDispatchContext: any = React.createContext({});
export const StatsStateRefContext: any = React.createContext({});


export const useStatsState = (): IStatsState => useContext(StatsStateContext);
export const useStatsDispatch = (): Dispatch<IAction> =>
  useContext(StatsDispatchContext);
export const useStatsStateRef = (): React.MutableRefObject<IStatsState> =>
  useContext(StatsStateRefContext);

const middleware = [
  logger("Stats"),
];

export const StatsProvider: React.FC = ({ children }) => {
    const [state, dispatch, statsStateRef] = useEnhancedReducer(
      reducer,
      initialState,
      (state) => ({ ...state }),
      middleware
    );
    
    
  
  
    return (
      <StatsStateContext.Provider value={state}>
        <StatsDispatchContext.Provider value={dispatch}>
          <StatsStateRefContext.Provider value={statsStateRef}>
            {children}
          </StatsStateRefContext.Provider>
        </StatsDispatchContext.Provider>
      </StatsStateContext.Provider>
    );
  };