/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
    useContext,
    useRef,
    useEffect,
    Dispatch,
    useCallback,
  } from "react";
  
  import { IHistoryState } from "./historycontext.types";
  
 
  import { IAction } from "../common/storebase.types";
  import { useEnhancedReducer } from "../common/enhancereducer.hook";
  import { logger } from "../common/logger";
  import { useApi } from "../api/api.context";
  
  
  export enum HistoryActions {
    lat = "lat",
    lng = "lng",
    page = "page",
    radius = "radius",
    name = "name",
    startDate = "startDate",
    endDate = "endDate",
    events = "events",
    totalNumberOfEvents = 'totalNumberOfEvents'
  }
  
  const initialState = {
    lat: null,
    lng: null,
    radius: null,
    name: null,
    page: 0,
    startDate: "",
    endDate: "",
    events: null,
    totalNumberOfEvents: null
  };
  
  // export enum TokenValidation {
  //   Valid = 'valid',
  //   Invalid = 'invalid',
  //   Exp = 'exp'
  // }
  
  // const initializer = (): IUserState => {
  //   if (checkTokenValid() === TokenValidation.Valid) {
  //     const tokenStr = window.localStorage.getItem('token')
  //     const storedUser = window.localStorage.getItem('user') ?? ''
  //     return {
  //       token: tokenStr,
  //       user: storedUser ? JSON.parse(storedUser) : null
  //     }
  //   } else {
  //     return initialState
  //   }
  // }
  
  // export const getValidToken = (): string | false | null => {
  //   const tokenStr = window.localStorage.getItem('token')
  //   if (checkTokenValid(tokenStr) === TokenValidation.Valid) {
  //     return tokenStr
  //   }
  //   return false
  // }
  
  // const checkTokenValid = (tokenStr?: string | null): TokenValidation => {
  //   tokenStr = tokenStr ?? window.localStorage.getItem('token')
  //   let token: IToken | undefined
  //   if (tokenStr) {
  //     token = jwtdecode(tokenStr)
  //   }
  //   if (!token) return TokenValidation.Invalid
  //   if (token.exp < Date.now() / 1000) return TokenValidation.Exp
  //   return TokenValidation.Valid
  // }
  
  // interface IToken {
  //   exp: number
  //   iat: number
  //   user: User
  // }
  
  const reducer = (state: IHistoryState, action: IAction): IHistoryState => {
    const newState = { ...state };
    switch (action.type) {
      case HistoryActions.lat:
        newState.lat = action.payload;
        break;
      case HistoryActions.lng:
        newState.lng = action.payload;
        break;
      case HistoryActions.radius:
        newState.radius = action.payload;
        break;
      case HistoryActions.name:
        newState.name = action.payload;
        break;
      case HistoryActions.page:
        newState.page = action.payload;
        break;
      case HistoryActions.events:
        newState.events = action.payload;
        break;
      case HistoryActions.startDate:
        newState.startDate = action.payload;
        break;
      case HistoryActions.endDate:
        newState.endDate = action.payload;
        break;
      case HistoryActions.totalNumberOfEvents:
        newState.totalNumberOfEvents = action.payload;
        break;
      default:
        return state;
    }
  
    return newState;
  };
  
  export const HistoryStateContext: any = React.createContext({});
  export const HistoryDispatchContext: any = React.createContext({});
  export const HistoryStateRefContext: any = React.createContext({});
  
  
  export const useHistoryState = (): IHistoryState => useContext(HistoryStateContext);
  export const useHistoryDispatch = (): Dispatch<IAction> =>
    useContext(HistoryDispatchContext);
  export const useHistoryStateRef = (): React.MutableRefObject<IHistoryState> =>
    useContext(HistoryStateRefContext);
  
  const middleware = [
    logger("History"),
    
  ];
  
  export const HistoryProvider: React.FC = ({ children }) => {
    const [state, dispatch, userStateRef] = useEnhancedReducer(
      reducer,
      initialState,
      (state) => ({ ...state }),
      middleware
    );
    // const { getUser, getHomeData, getClient } = useApi();
  
    // const getFullUser = async () => {
    //   if (state?.user?._id) {
    //     const resp = await getUser(state?.user?._id);
    //     if (resp?.Success) {
    //       console.log("resp getuser: ", resp?.data);
    //       dispatch({ type: UserActions.user, payload: resp.data });
    //     }
    //   }
  
    //   if(state?.user?.clientId) {
    //     const resp = await getClient(state?.user?.clientId);
    //     if(resp?.Success) {
    //       console.log('client from clientid: ', resp.data)
    //       dispatch({ type: UserActions.client, payload: (resp.data as Models.Client) })
    //     }
    //   }
      
    //   const resp = await getHomeData();
    //   if (resp?.Success) {
    //     // Set Locations context
    //     dispatch({ type: UserActions.locations, payload: (resp.data as Models.HomePageResponse).locations });
  
    //     dispatch({ type: DocumentActions.setDocuments, payload: (resp.data as Models.HomePageResponse).documents })
    //     // Set Client context
    //     // dispatch({ type: UserActions.client, payload: (resp.data as HomePageResponse).client })
  
    //     // Set current location to the first location returned
    //     dispatch({ type: UserActions.currentLocation, payload: (resp.data as Models.HomePageResponse).locations[0] })
    //   }
      
    // };
  
    // const loginAttempt = useRef(false);
    // if (!loginAttempt.current && !state.user) {
    //   dispatch({ type: UserActions.loginFromStorage });
    //   // getFullUser();
    //   loginAttempt.current = true;
    // }
    // // HomePageResponse
    // useEffect(() => {
    //   getFullUser();
    // }, [state?.token]);
  
    return (
      <HistoryStateContext.Provider value={state}>
        <HistoryDispatchContext.Provider value={dispatch}>
          <HistoryStateRefContext.Provider value={userStateRef}>
            {children}
          </HistoryStateRefContext.Provider>
        </HistoryDispatchContext.Provider>
      </HistoryStateContext.Provider>
    );
  };
  