import React from "react";
import { useState, useEffect } from "react";
import {
  Grid,
  Tabs,
  Tab,
  OutlinedInput,
  TextField,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { mainTheme } from "../../../styles/themes";
import { PageContainer } from "../../../components/generic.components/layout/pagelayout.component";
import { useMatch, useLocation, useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import { LoadingButton } from "@mui/lab";
import { useFormik } from "formik";
import { convert, convertMany } from "convert";
import {
  useSearchDispatch,
  useSearchState,
} from "../../../contexts/search/search.context";
import { SearchActions } from "../../../contexts/search/search.context";
import { getPointsFromZip } from "../geocode/geocode";
import * as yup from "yup";
import { NestCamWiredStandTwoTone, Search } from "@mui/icons-material";
import Geocode from "react-geocode";
import { useApi } from "../../../contexts/api/api.context";
import { isFuture, format, addMonths } from "date-fns";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  StatsActions,
  useStatsDispatch,
} from "../../../contexts/stats/stats.context";

type SearchEventsContainerProps = {};

// const searchEventSchema = yup.object().shape({

// })

export const SearchEventsContainer: React.FC<
  SearchEventsContainerProps
> = ({}) => {
  const classes = useStyles();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [path, setPath] = useState(location.pathname);
  const [zipcode, setZipCode] = useState<string>("");
  const [radius, setRadius] = useState<number>(50);
  const [name, setName] = useState<string>("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const searchDispatch = useSearchDispatch();
  const { lat, lng, page, events, userCount } = useSearchState();
  const { searchEvents, getStats, getAllStats } = useApi();
  const [startDateError, setStartDateError] = useState(false);
  const [endDateError, setEndDateError] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const statsDispatch = useStatsDispatch();

  useEffect(() => {
    searchDispatch({ type: SearchActions.radius, payload: radius });
    if (zipcode.length === 5) {
      dispatchLatLong();
    }
  }, [zipcode]);

  useEffect(() => {
    getAll()
  }, [])

  const dispatchLatLong = () => {
    Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY!);
    Geocode.setLocationType("ROOFTOP");
    Geocode.fromAddress(zipcode)
      .then((response) => {
        const { lat, lng } = response.results[0].geometry.location;
        searchDispatch({ type: SearchActions.lat, payload: lat });
        searchDispatch({ type: SearchActions.lng, payload: lng });
      })
      .catch((error) => {
        setError(error);
      });
  };

  const getAll = async () => {
    let res = await getAllStats();
    if (res?.data) {
      statsDispatch({ type: StatsActions.totalRead, payload: res.data.numRead});
      statsDispatch({ type: StatsActions.totalSent, payload: res.data.numSent});
    }
  }

  const handleTabsChange = (e: React.SyntheticEvent, path: string) => {
    navigate(path);
  };

  const handleStartDateChange = (e: any) => {
    if (isFuture(new Date(e.target.value)) === true) {
      let date = encodeURIComponent(e.target.value + ":00");
      setStartDate(date);
      setStartDateError(false);
      console.log(startDate);
      searchDispatch({
        type: SearchActions.startDate,
        payload: date
      })
    } else {
      setStartDateError(true);
      console.log("error set");
    }
  };

  const handleEndDateChange = (e: any) => {
    if (isFuture(new Date(e.target.value)) === true) {
      let date = encodeURIComponent(e.target.value + ":00");
      setEndDate(date);
      setEndDateError(false);
      console.log(endDate);
      searchDispatch({
        type: SearchActions.endDate,
        payload: date
      })
    } else {
      setEndDateError(true);
      console.log("error set");
    }
  };

  

  const handleRadiusChange = (e: any) => {
    setRadius(parseInt(e.target.value));
    
    searchDispatch({
      type: SearchActions.radius,
      payload: parseInt(e.target.value),
    });
    
  };

  const handleNameChange = (e: any) => {
    setName(e.target.value);
    searchDispatch({ type: SearchActions.name, payload: e.target.value });
  };

  const handleZipChange = (e: any) => {
    setZipCode(e.target.value);
  };

  const handleSubmit = async () => {
    setLoading(true);
    let area = await getStats({
      radius: convert(radius, "miles").to("meters"),
      lat: lat!,
      lng: lng!,
    });
    statsDispatch({
      type: StatsActions.areaRead,
      payload: area?.data.numRead,
    });
    statsDispatch({
      type: StatsActions.areaSent,
      payload: area?.data.numSent,
    });
    if (name && name.length > 3) {
      let res = await searchEvents({
        radius: convert(radius, "miles").to("meters"),
        lat: lat!,
        lng: lng!,
        startDate: startDate,
        endDate: endDate,
        page: page,
        name: name,
      });
      if (res) {
        searchDispatch({
          type: SearchActions.totalNumberOfEvents,
          payload: res.data.events.totalElements
        })
        searchDispatch({
          type: SearchActions.events,
          payload: res.data.events.content,
        });
        searchDispatch({
          type: SearchActions.userCount,
          payload: res.data.userCount,
        });
      } else {
        console.log("Something Went wrong");
      }
    } else {
      let area = await getStats({
        radius: convert(radius, "miles").to("meters"),
        lat: lat!,
        lng: lng!,
      });
      statsDispatch({
        type: StatsActions.areaRead,
        payload: area?.data.numRead,
      });
      statsDispatch({
        type: StatsActions.areaSent,
        payload: area?.data.numSent,
      });
      let res = await searchEvents({
        radius: convert(radius, "miles").to("meters"),
        lat: lat!,
        lng: lng!,
        startDate: startDate,
        endDate: endDate,
        page: page,
      });
      if (res) {
        console.log(res);
        searchDispatch({
          type: SearchActions.totalNumberOfEvents,
          payload: res.data.events.totalElements
        })
        searchDispatch({
          type: SearchActions.events,
          payload: res.data.events.content,
        });
        searchDispatch({
          type: SearchActions.userCount,
          payload: res.data.userCount,
        });
      }
    }
    setLoading(false);
  };
  // radius: convert(radius, "miles").to("meters"),
  // lat: lat!,
  // lng: lng!,
  return (
    <PageContainer className={classes.page}>
      <Grid item display="flex" sx={{ width: "100%" }}>
        <Grid container direction="row" display="flex" spacing={10}>
          <Grid item>
            <Tabs
              onChange={handleTabsChange}
              value={path}
              textColor="primary"
              indicatorColor="primary"
            >
              <Tab value={"/search"} disableRipple label="Search" />
              <Tab value={"/history"} disableRipple label="History" />
            </Tabs>
          </Grid>
          <Grid item display="flex" alignItems="center">
            <Grid
              container
              direction="row"
              display="flex"
              alignItems="center"
              spacing={2}
            >
              <Grid item>
                <Grid
                  container
                  direction="row"
                  display="flex"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item>
                    <div
                      style={{
                        fontFamily: mainTheme.fonts.primary.bold,
                        color: "#5A5A5A",
                        fontSize: "20px",
                      }}
                    >
                      Enter Search Zipcode
                    </div>
                  </Grid>
                  <Grid item>
                    <TextField
                      size="small"
                      inputProps={{ maxLength: 5, pattern: "[0-9]*" }}
                      onChange={handleZipChange}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid
                  container
                  direction="row"
                  display="flex"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item>
                    <div
                      style={{
                        fontFamily: mainTheme.fonts.primary.bold,
                        color: "#5A5A5A",
                        fontSize: "20px",
                      }}
                    >
                      Select Search Radius
                    </div>
                  </Grid>
                  <Grid item sx={{ paddingRight: "40px" }}>
                    <Select
                      size="small"
                      sx={{ width: "150px" }}
                      onChange={handleRadiusChange}
                      value={radius}
                    >
                      <MenuItem value={25}>25</MenuItem>
                      <MenuItem value={50}>50</MenuItem>
                      <MenuItem value={100}>100</MenuItem>
                      <MenuItem value={150}>150</MenuItem>
                      <MenuItem value={200}>200</MenuItem>
                    </Select>
                  </Grid>
                  <Grid item>
                    <LoadingButton
                      color="secondary"
                      variant="contained"
                      loadingPosition="start"
                      disabled={zipcode.length !== 5 || startDate.length < 3 || startDateError === true ? true : false}
                      loading={loading}
                      startIcon={<SearchIcon />}
                      sx={{ borderRadius: "42px", width: "225px" }}
                      onClick={handleSubmit}
                    >
                      Search
                    </LoadingButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item display="flex" width={"100%"} sx={{ paddingTop: "40px" }}>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
          display="flex"
          spacing={3}
        >
          <Grid item>
            <div
              style={{
                fontFamily: mainTheme.fonts.primary.bold,
                color: "#5A5A5A",
                fontSize: "20px",
              }}
            >
              Start Date
            </div>
          </Grid>
          <Grid item>
            <OutlinedInput
              onChange={handleStartDateChange}
              type="datetime-local"
              size="small"
              sx={{ border: startDateError ? '3px solid red' : null }}
            />
            {startDateError ? (
              <div
                style={{
                  fontFamily: mainTheme.fonts.primary.bold,
                  fontSize: "14px",
                  color: "red",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                Start Date must be in the future.
              </div>
            ) : (
              <></>
            )}
          </Grid>
          <Grid item>
            <div
              style={{
                fontFamily: mainTheme.fonts.primary.bold,
                color: "#5A5A5A",
                fontSize: "20px",
              }}
            >
              End Date
            </div>
          </Grid>
          <Grid item>
            <OutlinedInput
              type="datetime-local"
              size="small"
              onChange={handleEndDateChange}
              sx={{ border: endDateError ? "3px solid red" : null }}
            />
            {endDateError ? (
              <div
                style={{
                  fontFamily: mainTheme.fonts.primary.bold,
                  fontSize: "14px",
                  color: "red",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                End Date must be in the future.
              </div>
            ) : (
              <></>
            )}
          </Grid>
          <Grid item>
            <div
              style={{
                fontFamily: mainTheme.fonts.primary.bold,
                color: "#5A5A5A",
                fontSize: "20px",
              }}
            >
              Event Name
            </div>
          </Grid>
          <Grid item sx={{ paddingRight: "30px" }}>
            <OutlinedInput
              size="small"
              type="search"
              sx={{ width: "250px" }}
              onChange={handleNameChange}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        display="flex"
        justifyContent="center"
        sx={{ paddingTop: "40px", width: "100%" }}
      >
        <div
          style={{
            fontFamily: mainTheme.fonts.primary.bold,
            color: "#5A5A5A",
            fontSize: "22px",
            paddingBottom: '20px'
          }}
        >
          User Count: {userCount === null || undefined ? "--" : userCount}
        </div>
      </Grid>
      {/* This is where the headers will go */}
      {/* <Grid
        item
        display="flex"
        sx={{ paddingTop: "40px", width: "100%", paddingBottom: 1 }}
      >
        <Grid container direction="row" display="flex">
          <Grid item flex={5} display="flex">
            <div className={classes.headerText}>Client</div>
          </Grid>
          <Grid item flex={3} display="flex">
            <div className={classes.headerText}>Event Start/End Date</div>
          </Grid>

          <Grid item flex={3} display="flex" justifyContent="center">
            <div className={classes.headerText}>City/State</div>
          </Grid>
          <Grid item flex={4} display="flex" justifyContent="center">
            <div className={classes.headerText}>Create Notification</div>
          </Grid>
        </Grid>
      </Grid> */}
    </PageContainer>
  );
};

const useStyles = makeStyles((theme) => ({
  page: {
    bgColor: "white",
    paddingLeft: "20px",
    paddingTop: "20px",
    paddingRight: "20px",
    display: "flex",
  },
  headerText: {
    fontFamily: mainTheme.fonts.primary.bold,
    fontSize: "20px",
    color: "black",
  },
}));
