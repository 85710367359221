import React from 'react'
import { Grid, GridProps } from '@mui/material'
import { makeStyles, styled } from '@mui/styles'

export const PageContainer: React.ComponentType<GridProps> = styled(({ children, ...props }) => (
  <Grid container direction='column' {...props}>
    {children}
  </Grid>
))({
  width: '100%',
  height: '100%'
})

export const PageContainerRow: React.ComponentType<GridProps> = styled(({ children, ...props }) => (
  <Grid container direction='row' {...props}>
    {children}
  </Grid>
))({
  width: '100%',
  height: '100%'
})

export const GridMin: React.ComponentType<GridProps> = styled(({ children, ...props }) => (
  <Grid item {...props}>
    {children}
  </Grid>
))({
  flexBasis: 0,
  flexGrow: 0,
  minHeight: 'fit-content'
})

export const GridGrow: React.ComponentType<GridProps & { growWeight?: number, fitMin?: boolean }> = styled(({ children, growWeight, fitMin, ...props }) => (
  <Grid item {...props}>
    {children}
  </Grid>
))(({ theme, growWeight = 1, fitMin = false }) => ({
  minHeight: '0px',
  flexBasis: fitMin ? 'auto' : 0,
  flexGrow: growWeight
}))
