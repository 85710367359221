import React from "react";
import { useState, useEffect } from "react";
import {
  Grid,
  Button,
  TextField,
  Avatar,
  Divider,
  MenuItem,
  Input,
  Select,
  OutlinedInput,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { PageContainerRow } from "../../../components/generic.components/layout/pagelayout.component";
import test from "../../../styles/test-avi.png";
import { mainTheme } from "../../../styles/themes";
import { format, formatRelative, parseISO } from "date-fns";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { CancelRounded } from "@mui/icons-material";
import { useHistoryDispatch, useHistoryState } from "../../../contexts/history/history.context";
import { useStatsDispatch } from "../../../contexts/stats/stats.context";

type HistorySingleEventProps = {
  Event: any;
}

export const HistorySingleEvent: React.FC<HistorySingleEventProps> = ({
  Event,
}) => {
  const [loading, setLoading] = useState(false);
  const {lat, lng, radius, name, startDate, endDate} = useHistoryState()
  const historyDispatch = useHistoryDispatch()
  const statsDispatcch = useStatsDispatch()
  

  const handlePageChange = async () => {

  }

  // const [expanded, setExpanded] = useState(false);

  // const setExpandedClick = () => {
  //   if (expanded === false) {
  //     setExpanded(true);
  //   } else {
  //     setExpanded(false);
  //   }
  // };
  // This was for expanding the details, not enough details to show currently
      return (
        <PageContainerRow
          sx={{ paddingTop: 2, paddingLeft: 2, paddingRight: 2 }}
          display="flex"
        >
          <Grid item display="flex" flex={5}>
            <Grid container direction="row">
              <Grid item>
                <Avatar src={Event.image} sx={{ height: "90px", width: "90px" }} />
              </Grid>
              <Grid
                item
                sx={{ paddingLeft: "7px", position: "relative", top: "10px" }}
              >
                <div
                  style={{
                    paddingBottom: "5px",
                    fontFamily: mainTheme.fonts.primary.bold,
                    fontSize: "22px",
                    color: "#EC1748",
                  }}
                >
                  {/* Notification Title */}
                  {Event.name}
                </div>
                <div
                  style={{
                    fontFamily: mainTheme.fonts.primary.reg,
                    color: "#192338",
                    fontSize: "18px",
                  }}
                >
                  {Event.owner.displayName}
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item display="flex" flex={3} justifyContent="left">
            <div
              style={{
                fontFamily: mainTheme.fonts.primary.bold,
                color: "#1D223C",
                position: "relative",
                top: "10px",
                fontSize: "18px",
              }}
            >
              {/* Event Dates */}
              {format(parseISO(Event.startsAt), "P")} -{" "}
              {format(parseISO(Event.endsAt), "P")}
            </div>
          </Grid>
          <Grid
            item
            display="flex"
            flex={3}
            justifyContent="center"
          >
            <div
              style={{
                fontFamily: mainTheme.fonts.primary.bold,
                color: "#EC1748",
                fontSize: "20px",
                position: "relative",
                top: "10px",
              }}
            >
              {/*  */}
              {Event.location.city}, {Event.location.region}
            </div>
          </Grid>
          <Divider sx={{ width: "100%", paddingTop: 2 }} />
        </PageContainerRow>
      );
    };


// Switch statement for expanding for details, not enough details to expand on this proof of concept design


//     case true: {
//       return (
//         <PageContainerRow
//           sx={{ paddingTop: 2, paddingLeft: 2, paddingRight: 2 }}
//           display="flex"
//         >
//           <Grid item display="flex" flex={5}>
//             <Grid container direction="row">
//               <Grid item>
//                 <Avatar src={test} sx={{ height: "90px", width: "90px" }} />
//               </Grid>
//               <Grid
//                 item
//                 sx={{ paddingLeft: "7px", position: "relative", top: "10px" }}
//               >
//                 <div
//                   style={{
//                     paddingBottom: "5px",
//                     fontFamily: mainTheme.fonts.primary.bold,
//                     fontSize: "22px",
//                     color: "#EC1748",
//                   }}
//                 >
//                   {/* event title */}
//                 </div>
//                 <div
//                   style={{
//                     fontFamily: mainTheme.fonts.primary.reg,
//                     color: "#192338",
//                     fontSize: "18px",
//                   }}
//                 >
//                   {/* event owner */}
//                 </div>
//               </Grid>
//             </Grid>
//           </Grid>
//           <Grid item display="flex" flex={3} justifyContent="center">
//             <div
//               style={{
//                 fontFamily: mainTheme.fonts.primary.bold,
//                 color: "#1D223C",
//                 position: "relative",
//                 top: "10px",
//                 fontSize: "18px",
//               }}
//             >
//               {/* event dates */}
//             </div>
//           </Grid>
//           <Grid item display="flex" flex={3} justifyContent="center">
//             <div
//               style={{
//                 fontFamily: mainTheme.fonts.primary.bold,
//                 color: "#EC1748",
//                 fontSize: "20px",
//                 position: "relative",
//                 top: "10px",
//               }}
//             >
//               {/* event location */}
//             </div>
//           </Grid>
//           <Grid item display="flex" flex={3} sx={{ position: "relative", left: "25px" }}>
//             <div
//               style={{
//                 fontFamily: mainTheme.fonts.primary.bold,
//                 color: "#EC1748",
//                 fontSize: "20px",
//                 position: "relative",
//                 top: "10px",
//               }}
//             >
//               10345
//             </div>
//           </Grid>
//           <Grid item flex={4}>
//             <Button
//               color="error"
//               endIcon={<KeyboardArrowDownIcon />}
//               variant="contained"
//               sx={{ borderRadius: "42px", postition: "relative", left: "70px" }}
//               onClick={setExpandedClick}
//             >
//               Hide Details
//             </Button>
//           </Grid>
//           <Divider sx={{ width: "100%", paddingTop: 1, paddingBottom: 1 }} />
//           <Grid item display="flex" sx={{ width: "100%" }}>
//             <Grid
//               container
//               direction="row"
//               display="flex"
//               spacing={4}
//               sx={{ paddingTop: 2.5, paddingLeft: 1, paddingBottom: 2.5 }}
//             >
//               <Grid item flex={3}>
//                 <Grid container direction="row" spacing={1}>
//                   <Grid item>
//                     <div
//                       style={{
//                         fontSize: "18px",
//                         fontFamily: mainTheme.fonts.primary.bold,
//                         color: "#192338",
//                       }}
//                     >
//                       Scheduled/Sent Date
//                     </div>
//                     <div
//                       style={{
//                         paddingTop: "10px",
//                         fontSize: "16px",
//                         fontFamily: mainTheme.fonts.primary.bold,
//                         color: mainTheme.palette.primary.main,
//                       }}
//                     >
//                       {/* Scheduled Date */}
//                     </div>
//                   </Grid>
//                   {/* <Grid item>
//                     <div
//                       style={{
//                         fontSize: "18px",
//                         fontFamily: mainTheme.fonts.primary.bold,
//                         color: "#192338",
//                       }}
//                     >
//                       End Date
//                     </div>
//                     <OutlinedInput size="small" type="date" />
//                   </Grid> */}
//                 </Grid>
//               </Grid>
//               <Grid item flex={3}>
//                 <div
//                   style={{
//                     fontFamily: mainTheme.fonts.primary.bold,
//                     fontSize: "18px",
//                     color: "#192338",
//                     opacity: "100%",
//                   }}
//                 >
//                   City/State
//                 </div>
//                 <div
//                   style={{
//                     paddingTop: "10px",
//                     fontSize: "16px",
//                     fontFamily: mainTheme.fonts.primary.bold,
//                     color: mainTheme.palette.primary.main,
//                   }}
//                 >
//                   {/* city state */}
//                 </div>
//               </Grid>
//               <Grid item flex={3}>
//                 <div
//                   style={{
//                     fontFamily: mainTheme.fonts.primary.bold,
//                     fontSize: "18px",
//                     color: "#192338",
//                     opacity: "100%",
//                   }}
//                 >
//                   Selected Radius{" "}
//                 </div>
//                 <div
//                   style={{
//                     paddingTop: "10px",
//                     fontSize: "16px",
//                     fontFamily: mainTheme.fonts.primary.bold,
//                     color: mainTheme.palette.primary.main,
//                   }}
//                 >
//                   {/* Radius */}
//                 </div>
//               </Grid>
//               <Grid item flex={3}>
//                 <div
//                   style={{
//                     fontFamily: mainTheme.fonts.primary.bold,
//                     fontSize: "18px",
//                     color: "#192338",
//                     opacity: "100%",
//                   }}
//                 >
//                   Title
//                 </div>
//                 <div
//                   style={{
//                     paddingTop: "10px",
//                     fontSize: "16px",
//                     fontFamily: mainTheme.fonts.primary.bold,
//                     color: mainTheme.palette.primary.main,
//                   }}
//                 >
//                   {/* Notification Title */}
//                 </div>
//               </Grid>
//               <Grid item flex={4}>
//                 <div
//                   style={{
//                     fontFamily: mainTheme.fonts.primary.bold,
//                     fontSize: "18px",
//                     color: "#192338",
//                     opacity: "100%",
//                   }}
//                 >
//                   Message Sent
//                 </div>
//                 <OutlinedInput
//                   type="text"
//                   size="small"
//                   sx={{ width: "250px" }}
//                   multiline
                  
//                   maxRows={3}
//                   minRows={3}
//                   inputProps={{
//                     maxLength: 100,
//                     readOnly: true,
//                     style: { cursor: "default" },
//                   }}
//                 />
//               </Grid>

//               {/* remove something in the history, which we decided not to use 9/26 */}
//               {/* <Grid item flex={2}>
//                 <Button
//                   variant="contained"
//                   sx={{ borderRadius: "42px" }}
//                   startIcon={<CancelRounded />}
//                 >
//                   Remove
//                 </Button>
//               </Grid> */}
//             </Grid>
//           </Grid>
//         </PageContainerRow>
//       );
//       break;
//     }
//     default: {
//       return (
//         <PageContainerRow
//           sx={{ paddingTop: 2, paddingLeft: 2, paddingRight: 2 }}
//           display="flex"
//         >
//           <Grid item display="flex" flex={5}>
//             <Grid container direction="row">
//               <Grid item>
//                 <Avatar src={test} sx={{ height: "90px", width: "90px" }} />
//               </Grid>
//               <Grid
//                 item
//                 sx={{ paddingLeft: "7px", position: "relative", top: "10px" }}
//               >
//                 <div
//                   style={{
//                     paddingBottom: "5px",
//                     fontFamily: mainTheme.fonts.primary.bold,
//                     fontSize: "22px",
//                     color: "#EC1748",
//                   }}
//                 >
                  
//                 </div>
//                 <div
//                   style={{
//                     fontFamily: mainTheme.fonts.primary.reg,
//                     color: "#192338",
//                     fontSize: "18px",
//                   }}
//                 >
                  
//                 </div>
//               </Grid>
//             </Grid>
//           </Grid>
//           <Grid item display="flex" flex={3}>
//             <div style={{ fontFamily: mainTheme.fonts.primary.bold }}>
              
//             </div>
//           </Grid>
//           <Grid item display="flex" flex={3}>
//             <div>

//             </div>
//           </Grid>
//           <Grid item display="flex" flex={3}>
//             <div>

//             </div>
//           </Grid>
//           <Grid item flex={4}>
//             <Button color="error" variant="contained">
//               Expand Details
//             </Button>
//           </Grid>
//           <Divider sx={{ width: "100%", paddingTop: 1 }} />
//         </PageContainerRow>
//       );
//       break;
//     }
//   }
// };

const useStyles = makeStyles((theme) => ({
  page: {
    backgroundColor: "white",
  },
}));
