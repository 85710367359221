/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
    useContext,
    useRef,
    useEffect,
    Dispatch,
    useCallback,
  } from "react";
  
  import { ISearchState } from "./searchcontext.types";
  
 
  import { IAction } from "../common/storebase.types";
  import { useEnhancedReducer } from "../common/enhancereducer.hook";
  import { logger } from "../common/logger";
  import { useApi } from "../api/api.context";
  
  
  export enum SearchActions {
    lat = "lat",
    lng = "lng",
    radius = "radius",
    name = "name",
    events = "events",
    userCount = "userCount",
    page = "page",
    startDate = "startDate",
    endDate = "endDate",
    totalNumberOfEvents = "totalNumberOfEvents"
  }
  
  const initialState = {
    lat: null,
    lng: null,
    radius: null,
    name: null,
    events: null,
    userCount: null,
    page: 0,
    startDate: "",
    endDate: "",
    totalNumberOfEvents: null
  };
  
  // export enum TokenValidation {
  //   Valid = 'valid',
  //   Invalid = 'invalid',
  //   Exp = 'exp'
  // }
  
  // const initializer = (): IUserState => {
  //   if (checkTokenValid() === TokenValidation.Valid) {
  //     const tokenStr = window.localStorage.getItem('token')
  //     const storedUser = window.localStorage.getItem('user') ?? ''
  //     return {
  //       token: tokenStr,
  //       user: storedUser ? JSON.parse(storedUser) : null
  //     }
  //   } else {
  //     return initialState
  //   }
  // }
  
  // export const getValidToken = (): string | false | null => {
  //   const tokenStr = window.localStorage.getItem('token')
  //   if (checkTokenValid(tokenStr) === TokenValidation.Valid) {
  //     return tokenStr
  //   }
  //   return false
  // }
  
  // const checkTokenValid = (tokenStr?: string | null): TokenValidation => {
  //   tokenStr = tokenStr ?? window.localStorage.getItem('token')
  //   let token: IToken | undefined
  //   if (tokenStr) {
  //     token = jwtdecode(tokenStr)
  //   }
  //   if (!token) return TokenValidation.Invalid
  //   if (token.exp < Date.now() / 1000) return TokenValidation.Exp
  //   return TokenValidation.Valid
  // }
  
  // interface IToken {
  //   exp: number
  //   iat: number
  //   user: User
  // }
  
  const reducer = (state: ISearchState, action: IAction): ISearchState => {
    const newState = { ...state };
    switch (action.type) {
      case SearchActions.lat:
        newState.lat = action.payload;
        break;
      case SearchActions.lng:
        newState.lng = action.payload;
        break;
      case SearchActions.radius:
        newState.radius = action.payload;
        break;
      case SearchActions.name:
        newState.name = action.payload;
        break;
      case SearchActions.userCount:
        newState.userCount = action.payload;
        break;
      case SearchActions.endDate:
        newState.endDate = action.payload;
        break;
      case SearchActions.startDate:
        newState.startDate = action.payload;
        break;
      case SearchActions.page:
        newState.page = action.payload;
        break;
      case SearchActions.events:
        newState.events = action.payload;
        console.log(newState.events)
        break;
      case SearchActions.totalNumberOfEvents:
        newState.totalNumberOfEvents = action.payload;
        break;
      default:
        return state;
    }
  
    return newState;
  };
  
  export const SearchStateContext: any = React.createContext({});
  export const SearchDispatchContext: any = React.createContext({});
  export const SearchStateRefContext: any = React.createContext({});
  
  
  export const useSearchState = (): ISearchState => useContext(SearchStateContext);
  export const useSearchDispatch = (): Dispatch<IAction> =>
    useContext(SearchDispatchContext);
  export const useUserStateRef = (): React.MutableRefObject<ISearchState> =>
    useContext(SearchStateRefContext);
  
  
  
  const middleware = [
    logger("Search"),
    
  ];
  
  export const SearchProvider: React.FC = ({ children }) => {
    const [state, dispatch, userStateRef] = useEnhancedReducer(
      reducer,
      initialState,
      (state) => ({ ...state }),
      middleware
    );
    // const { getUser, getHomeData, getClient } = useApi();
  
    // const getFullUser = async () => {
    //   if (state?.user?._id) {
    //     const resp = await getUser(state?.user?._id);
    //     if (resp?.Success) {
    //       console.log("resp getuser: ", resp?.data);
    //       dispatch({ type: UserActions.user, payload: resp.data });
    //     }
    //   }
  
    //   if(state?.user?.clientId) {
    //     const resp = await getClient(state?.user?.clientId);
    //     if(resp?.Success) {
    //       console.log('client from clientid: ', resp.data)
    //       dispatch({ type: UserActions.client, payload: (resp.data as Models.Client) })
    //     }
    //   }
      
    //   const resp = await getHomeData();
    //   if (resp?.Success) {
    //     // Set Locations context
    //     dispatch({ type: UserActions.locations, payload: (resp.data as Models.HomePageResponse).locations });
  
    //     dispatch({ type: DocumentActions.setDocuments, payload: (resp.data as Models.HomePageResponse).documents })
    //     // Set Client context
    //     // dispatch({ type: UserActions.client, payload: (resp.data as HomePageResponse).client })
  
    //     // Set current location to the first location returned
    //     dispatch({ type: UserActions.currentLocation, payload: (resp.data as Models.HomePageResponse).locations[0] })
    //   }
      
    // };
  
    // const loginAttempt = useRef(false);
    // if (!loginAttempt.current && !state.user) {
    //   dispatch({ type: UserActions.loginFromStorage });
    //   // getFullUser();
    //   loginAttempt.current = true;
    // }
    // // HomePageResponse
    // useEffect(() => {
    //   getFullUser();
    // }, [state?.token]);
  
    return (
      <SearchStateContext.Provider value={state}>
        <SearchDispatchContext.Provider value={dispatch}>
          <SearchStateRefContext.Provider value={userStateRef}>
            {children}
          </SearchStateRefContext.Provider>
        </SearchDispatchContext.Provider>
      </SearchStateContext.Provider>
    );
  };
  
  
  
  // export const usePermissionCheck = () => {
  //   const userState = useUserState()
  
  //   const runCheck = useCallback(
  //     (permission: Permission, ffl?: Models.FFLPage, fflLic?: Models.FFLLicense) => {
  //       const u = userState.user
  //       switch (permission) {
  //         case Permission.manageFFL:
  //           if (ffl) {
  //             // is specific ffl manager
  //             return (u?.role === Enums.Role.FFLManager || u?.role === Enums.Role.FFLAssociate) && u._ffl && u._ffl === ffl?._id
  //           } else {
  //             // is ANY ffl manager
  //             return u?.role === Enums.Role.FFLManager || u?.role === Enums.Role.FFLAssociate
  //           }
  //         default:
  //           return false
  //       }
  //     },
  //     [userState.user]
  //   )
  //   return runCheck
  // }
  
  // export const useCheckTokenValid = (): [() => TokenValidation, (valid?: TokenValidation | undefined) => void] => {
  //   const userDispatch = useUserDispatch()
  //   const userStateRef = useUserStateRef()
  
  //   const handleLogCheck = useCallback(
  //     (valid?: TokenValidation) => {
  //       if (!valid) {
  //         valid = checkTokenValid()
  //       }
  //       if (userStateRef.current.token) {
  //         if (valid === TokenValidation.Exp) {
  //           userDispatch({ type: UserActions.login })
  //         }
  //         if (valid === TokenValidation.Invalid) {
  //           userDispatch({ type: UserActions.logout })
  //         }
  //       }
  //     },
  //     [userDispatch, userStateRef]
  //   )
  //   return [checkTokenValid, handleLogCheck]
  // }