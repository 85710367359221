import React from "react";
import { useState, useEffect } from "react";
import {
  Grid,
  Button,
  TextField,
  Avatar,
  Checkbox,
  Divider,
  Box,
  MenuItem,
  Input,
  Modal,
  Select,
  OutlinedInput,
  Hidden,
  CircularProgress,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { PageContainerRow } from "../../../components/generic.components/layout/pagelayout.component";
import test from "../../../styles/test-avi.png";
import { mainTheme } from "../../../styles/themes";
import {
  format,
  formatRelative,
  parseISO,
  parseJSON,
  isFuture,
  addMinutes,
  formatISO,
  isAfter,
} from "date-fns";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { CancelRounded, ConstructionOutlined } from "@mui/icons-material";
import { useSearchState } from "../../../contexts/search/search.context";
import { date } from "yup/lib/locale";
import { useApi } from "../../../contexts/api/api.context";
import { convert } from "convert";
import { SubmittedModal } from "../../common/submitted.modal";
import { LoadingButton } from "@mui/lab";
import SendIcon from '@mui/icons-material/Send';


type SearchSingleEventProps = {
  Event: any;
};

export const SearchSingleEvent: React.FC<SearchSingleEventProps> = ({
  Event,
}) => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [messageCharCount, setMessageCharCount] = useState(0);
  const [titleCharCount, setTitleCharCount] = useState(0);
  const [notificationDate, setNotificationDate] = useState("");
  const [dateError, setDateError] = useState(false);
  const [sendNow, setSendNow] = useState(false);
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const { lat, lng, radius } = useSearchState();
  const { sendNowNotification, scheduleNotification } = useApi();

  const handleSendNow = (e: any) => {
    setSendNow(e.target.checked);
  };

  const handleModalClose = () => {
    setExpanded(false);
    setOpen(false);
  };

  

  const handleNotificationDateChange = (e: any) => {
    if (
      isAfter(
        new Date(e.target.value),
        addMinutes(new Date(Date.now()), 5)
      ) === true
    ) {
      let formatted = new Date(e.target.value).toJSON();
      setNotificationDate(formatted);
      setDateError(false);
    } else {
      setDateError(true);
      console.log("error set");
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    if (sendNow === true) {
      let date = new Date(Date.now());
      let formatDate = date.toJSON();
      console.log(formatDate);
      let fullMessage: string =
        Event.url === null || undefined
          ? message
          : `${message} Visit ${Event.url} for details.`;
      let res = await sendNowNotification({
        eventId: Event.id,
        lat: lat!,
        lng: lng!,
        message: fullMessage,
        radius: convert(radius!, "miles").to("meters"),
        schedule: formatDate,
        title: title,
      });
      setLoading(false);
      setOpen(true);
    } else {
      let fullMessage =
        Event.url === null || undefined
          ? message
          : `${message} Visit ${Event.url} for details.`;
      let res = await scheduleNotification({
        eventId: Event.id,
        lat: lat!,
        lng: lng!,
        message: fullMessage,
        radius: convert(radius!, "miles").to("meters"),
        schedule: notificationDate,
        title: title,
      });
      setLoading(false);
      setOpen(true);
    }
  };

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 300,
    height: 150,
    display: "flex",
    bgcolor: "background.paper",
    border: "3px solid #EC1748",
    borderRadius: "8px",
    boxShadow: 24,
    p: 5,
  };

  const setExpandedClick = () => {
    if (expanded === false) {
      setExpanded(true);
    } else {
      setExpanded(false);
    }
  };

  const notificationMessageOnChange = (e: any) => {
    setMessageCharCount(e.target.value.length);
    setMessage(e.target.value);
  };

  const notificationTitleOnChange = (e: any) => {
    setTitleCharCount(e.target.value.length);
    setTitle(e.target.value);
  };

  switch (expanded) {
    case false: {
      return (
        <PageContainerRow
          sx={{ paddingTop: 2, paddingLeft: 2, paddingRight: 2 }}
          display="flex"
        >
          <Grid item display="flex" flex={5}>
            <Grid container direction="row">
              <Grid item>
                <Avatar
                  src={Event.image}
                  sx={{ height: "90px", width: "90px" }}
                />
              </Grid>
              <Grid
                item
                sx={{ paddingLeft: "7px", position: "relative", top: "10px" }}
              >
                <div
                  style={{
                    paddingBottom: "5px",
                    fontFamily: mainTheme.fonts.primary.bold,
                    fontSize: "22px",
                    color: "#EC1748",
                  }}
                >
                  {Event.name}
                  {/* even title */}
                </div>
                <div
                  style={{
                    fontFamily: mainTheme.fonts.primary.reg,
                    color: "#192338",
                    fontSize: "18px",
                  }}
                >
                  {Event.owner.displayName}
                  {/* client name */}
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item display="flex" flex={3}>
            <div
              style={{
                fontFamily: mainTheme.fonts.primary.bold,
                color: "#1D223C",
                position: "relative",
                top: "10px",
                fontSize: "18px",
              }}
            >
              {/* start date and end date */}
              {format(parseISO(Event.startsAt), "P")} -{" "}
              {format(parseISO(Event.endsAt), "P")}
            </div>
          </Grid>

          <Grid item display="flex" flex={3} justifyContent="center">
            <div
              style={{
                fontFamily: mainTheme.fonts.primary.bold,
                color: "#EC1748",
                fontSize: "20px",
                position: "relative",
                top: "10px",
              }}
            >
              {/* even city or state */}
              {`${Event.location.city}, ${Event.location.region}`}
            </div>
          </Grid>
          <Grid item flex={4}>
            <Button
              color="error"
              variant="contained"
              endIcon={<KeyboardArrowRightIcon />}
              sx={{ borderRadius: "42px", position: "relative", left: "70px" }}
              onClick={setExpandedClick}
            >
              Create Notification
            </Button>
          </Grid>
          <Divider sx={{ width: "100%", paddingTop: 2 }} />
        </PageContainerRow>
      );
    }
    case true: {
      return (
        <PageContainerRow
          sx={{ paddingTop: 2, paddingLeft: 2, paddingRight: 2 }}
          display="flex"
        >
          <Modal open={open} onClose={handleModalClose}>
            <Box sx={style}>
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={5}
              >
                <Grid item>
                  <div
                    style={{
                      fontFamily: mainTheme.fonts.primary.bold,
                      color: "#1D223C",
                      fontSize: "22px",
                    }}
                  >
                    Notification Scheduled!
                  </div>
                </Grid>
                <Grid item>
                  <Button
                    color="primary"
                    variant="contained"
                    sx={{ borderRadius: "42px" }}
                    onClick={handleModalClose}
                  >
                    Close
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Modal>
          <Grid item display="flex" flex={5}>
            <Grid container direction="row">
              <Grid item>
                <Avatar
                  src={Event.image}
                  sx={{ height: "90px", width: "90px" }}
                />
              </Grid>
              <Grid
                item
                sx={{ paddingLeft: "7px", position: "relative", top: "10px" }}
              >
                <div
                  style={{
                    paddingBottom: "5px",
                    fontFamily: mainTheme.fonts.primary.bold,
                    fontSize: "22px",
                    color: "#EC1748",
                  }}
                >
                  {/* event title */}
                  {Event.name}
                </div>
                <div
                  style={{
                    fontFamily: mainTheme.fonts.primary.reg,
                    color: "#192338",
                    fontSize: "18px",
                  }}
                >
                  {/* event client name */}
                  {Event.owner.displayName}
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item display="flex" flex={3}>
            <div
              style={{
                fontFamily: mainTheme.fonts.primary.bold,
                color: "#1D223C",
                position: "relative",
                top: "10px",
                fontSize: "18px",
              }}
            >
              {/* event start date and end date */}
              {format(parseISO(Event.startsAt), "P")} -{" "}
              {format(parseISO(Event.endsAt), "P")}
            </div>
          </Grid>
          <Grid item display="flex" flex={3} justifyContent="center">
            <div
              style={{
                fontFamily: mainTheme.fonts.primary.bold,
                color: "#EC1748",
                fontSize: "20px",
                position: "relative",
                top: "10px",
              }}
            >
              {/* event city and state  */}
              {`${Event.location.city}, ${Event.location.region}`}
            </div>
          </Grid>
          <Grid item flex={4}>
            <Button
              color="error"
              endIcon={<KeyboardArrowDownIcon />}
              variant="contained"
              sx={{ borderRadius: "42px", postition: "relative", left: "70px" }}
              onClick={setExpandedClick}
            >
              Create Notification
            </Button>
          </Grid>
          <Divider sx={{ width: "100%", paddingTop: 1, paddingBottom: 1 }} />
          <Grid item display="flex" sx={{ width: "100%" }}>
            <Grid
              container
              direction="row"
              display="flex"
              spacing={4}
              sx={{ paddingTop: 2.5, paddingLeft: 1, paddingBottom: 2.5 }}
            >
              <Grid item flex={4}>
                <Grid container direction="row" spacing={1}>
                  <Grid item>
                    <div
                      style={{
                        fontSize: "18px",
                        fontFamily: mainTheme.fonts.primary.bold,
                        color: "#192338",
                      }}
                    >
                      Select Notification Date
                    </div>
                    <OutlinedInput
                      size="small"
                      type="datetime-local"
                      onChange={handleNotificationDateChange}
                      fullWidth
                      sx={{ border: dateError === true ? '3px solid red' : null}}
                    />
                  </Grid>
                  <div
                    style={{
                      paddingLeft: "30px",
                      position: "relative",
                      top: "40px",
                      paddingRight: "20px",
                    }}
                  >
                    or
                  </div>
                  <Grid item>
                    <div
                      style={{
                        fontSize: "18px",
                        fontFamily: mainTheme.fonts.primary.bold,
                        color: "#192338",
                      }}
                    >
                      Send Now
                    </div>
                    <Checkbox
                      checked={sendNow}
                      onChange={handleSendNow}
                      sx={{ position: "relative", left: "15px", bottom: "2px" }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item flex={2}>
                <div
                  style={{
                    fontFamily: mainTheme.fonts.primary.bold,
                    fontSize: "18px",
                    color: "#192338",
                    opacity: "100%",
                  }}
                >
                  Notification Title
                </div>
                <TextField
                  size="small"
                  onChange={notificationTitleOnChange}
                  helperText={`${titleCharCount}/30`}
                  inputProps={{ maxLength: 30 }}
                  placeholder={"Enter a Title."}
                />
              </Grid>
              {/* Radius based on zip code will be the radius they are searching for  */}
              {/* <Grid item flex={4}>
                <div
                  style={{
                    fontFamily: mainTheme.fonts.primary.bold,
                    fontSize: "18px",
                    color: "#192338",
                    opacity: "100%",
                  }}
                >
                  Radius based on ZIP Code{" "}
                </div>

                
                <Select size="small" value={event.radius} fullWidth>
                  <MenuItem value={25}>25 Miles</MenuItem>
                </Select>
              </Grid> */}
              <Grid item flex={4}>
                <div
                  style={{
                    fontFamily: mainTheme.fonts.primary.bold,
                    fontSize: "18px",
                    color: "#192338",
                    opacity: "100%",
                  }}
                >
                  Notification Message
                </div>
                <TextField
                  size="small"
                  onChange={notificationMessageOnChange}
                  multiline
                  placeholder="Enter a short message for the notification."
                  helperText={`${messageCharCount}/100`}
                  minRows={3}
                  maxRows={3}
                  inputProps={{ maxLength: 100 }}
                  sx={{ width: "350px" }}
                />
              </Grid>
              {/* <Grid item flex={2}>
                <Button variant="contained" sx={{ borderRadius: '42px'}} startIcon={<CancelRounded />}>End Event</Button>
              </Grid> */}
              <Grid item flex={2}>
                <LoadingButton
                  variant="contained"
                  color="secondary"
                  onClick={handleSubmit}
                  sx={{ borderRadius: "42px" }}
                  loading={loading}
                  endIcon={<SendIcon />}
                  loadingIndicator={<CircularProgress size={18}/>}
                  loadingPosition='end'
                >
                  Submit
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>
        </PageContainerRow>
      );
      break;
    }
    default: {
      return (
        <PageContainerRow
          sx={{ paddingTop: 2, paddingLeft: 2, paddingRight: 2 }}
          display="flex"
        >
          <Grid item display="flex" flex={5}>
            <Grid container direction="row">
              <Grid item>
                <Avatar src={test} sx={{ height: "90px", width: "90px" }} />
              </Grid>
              <Grid
                item
                sx={{ paddingLeft: "7px", position: "relative", top: "10px" }}
              >
                <div
                  style={{
                    paddingBottom: "5px",
                    fontFamily: mainTheme.fonts.primary.bold,
                    fontSize: "22px",
                    color: "#EC1748",
                  }}
                >
                  {/* even title */}
                </div>
                <div
                  style={{
                    fontFamily: mainTheme.fonts.primary.reg,
                    color: "#192338",
                    fontSize: "18px",
                  }}
                >
                  {/* client name */}
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item display="flex" flex={3}>
            <div
              style={{
                fontFamily: mainTheme.fonts.primary.bold,
                color: "#1D223C",
                position: "relative",
                top: "10px",
                fontSize: "18px",
              }}
            >
              {/* start date and end date */}
            </div>
          </Grid>

          <Grid item display="flex" flex={3} justifyContent="center">
            <div
              style={{
                fontFamily: mainTheme.fonts.primary.bold,
                color: "#EC1748",
                fontSize: "20px",
                position: "relative",
                top: "10px",
              }}
            >
              {/* even city or state */}
            </div>
          </Grid>
          <Grid item flex={4}>
            <Button
              color="error"
              variant="contained"
              endIcon={<KeyboardArrowRightIcon />}
              sx={{ borderRadius: "42px", position: "relative", left: "70px" }}
              onClick={setExpandedClick}
            >
              Create Notification
            </Button>
          </Grid>
          <Divider sx={{ width: "100%", paddingTop: 2 }} />
        </PageContainerRow>
      );
      break;
    }
  }
};

const useStyles = makeStyles((theme) => ({
  page: {
    backgroundColor: "white",
  },
}));
