import { createTheme } from '@mui/material/styles'

export const mainTheme = createTheme({
  palette: {
    common: {
      black: '#393939',
      darkGrey: '#5A5A5A',
      medDarkGrey: '#B7B7B7',
      medGrey: '#B7B7B7',
      lightGrey: '#E6E6E6',
      link: '#5E6A71'
    },
    primary: {
      light: '#85A7C6',
      main: '#EC1748',
      contrastText: '#FFFFFF'
    },
    secondary: {
      main: '#A2CA62',
      contrastText: '#FFFFFF'
    },
    success: {
      main: '#08AF15'
    },
    error: {
      main: '#A0A3AA',
      contrastText: 'white'
    },
    warning: {
      main: '#ff8300'
    },
    background: {
      paper: '#FFFFFF'
    },
    text: {
      primary: '#000000',
      secondary: '#5A5A5A'
    }
    // gradients: {
    //   primary: 'linear-gradient(180deg, #01538f 0%, #022138 100%) 0% 0% no-repeat padding-box'
    // }
  },
  typography: {
    fontFamily: [
      'ProximaNova',
      'DINRoundPro-Bold',
      'DINRoundPro-Light',
      'DINRoundPro',
      'DINRoundPro-Medi',
      '-apple-system',
      'BlinkMacSystemFont',
      'Segoe UI',
      'Roboto',
      'Oxygen',
      'Ubuntu',
      'Cantarell',
      'Fira Sans',
      'Droid Sans',
      'Helvetica Neue',
      'sans-serif'
    ].join(','),
    button: {
      textTransform: 'revert',
      fontSize: '1.125rem'
    }
  },
  fonts: {
    primary: {
      light: 'DINRoundPro-Light',
      reg: 'DINRoundPro',
      med: 'DINRoundPro-Medi',
      bold: 'DINRoundPro-Bold',
    },
    secondary: {
      light: 'Helvetica-light',
      reg: 'Helvetica',
      med: 'Helvetica-med',
      bold: 'Helvetica-bold',
      condBold: 'Helvetica-cdbold'
    }
  },
  components: {

  },
  breakpoints: {
    values: {
      // defaults
      xs: 0,
      sm: 600,
    //   mdsm: 720,
      md: 960,
      lg: 1280,
      xl: 1920
      // added
    }
  },
//   overrides: {
//     MuiCssBaseline: {
//       '@global': {
//         'html, body, #root': {
//           width: '100vw',
//           height: '100vh',
//           overflow: 'hidden'
//         },
//         '.scrollbar::-webkit-scrollbar': {
//           width: '19px',
//           height: '19px'
//         },

//         '.scrollbar::-webkit-scrollbar-track': {
//           boxShadow: 'inset 0 0 3px 3px #D3D3D3',
//           borderRadius: '999px',
//           border: '3px solid transparent'
//         },

//         '.scrollbar::-webkit-scrollbar-thumb': {
//           boxShadow: 'inset 0 0 2px 2px #989898',
//           borderRadius: '999px',
//           border: '7px solid transparent'
//         },

//         '.scrollbar::-webkit-scrollbar-corner': {
//           backgroundColor: 'transparent'
//         },
//         '.MuiButton-containedPrimary:hover': {
//           backgroundColor: 'rgb(245, 167, 51)'
//         }
//       }
//     }
//   }
})
