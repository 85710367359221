/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { createContext, useContext, useEffect, useMemo } from 'react'
import { UserActions, useUserDispatch, useUserState } from '../user/user.context'


import { createFuncs } from './api.functions'

export type IAPI = ReturnType<typeof createFuncs>

const ApiContext: any = createContext({})

export const useApi = (): IAPI => useContext(ApiContext)

export const ApiProvider: React.FC = ({ children }) => {
  const userState = useUserState()
  const userDispatch = useUserDispatch()

  const funcs = useMemo(() => {
    return createFuncs(userDispatch)
  }, [userState.token, userDispatch])

  // useEffect(() => {
  //   userState.token &&
  //     funcs.getSettings().then((res) => {
  //       if (res?.Status === 'Success') {
  //         userDispatch({ type: UserActions.updateUser, payload: res.Data })
  //       }
  //     })
  // }, [funcs.getSettings, userState.token])

  return <ApiContext.Provider value={funcs}>{children}</ApiContext.Provider>
}
