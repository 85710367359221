/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
  useContext,
  useRef,
  useEffect,
  Dispatch,
  useCallback,
} from "react";

import { IUserState } from "./usercontext.type";

import {
  getStoredUser,
  loginFromStorage,
  relog,
  userLogin,
  userLogout,
} from "./user.functions";
import { IAction } from "../common/storebase.types";
import { useEnhancedReducer } from "../common/enhancereducer.hook";
import { logger } from "../common/logger";
import { useApi } from "../api/api.context";
import { StatsActions, useStatsDispatch, useStatsState } from "../stats/stats.context";
import { Stats } from "fs";

export enum UserActions {
  login = "login",
  loginSession = "loginSession",
  logout = "logout",
  loginAfterForgotPassword = "forgotPassword",
  loginError = "loginError",
  loading = "loading",
  loginFromStorage = "loginFromStorage",
  user = "user",
  token = "token",
  refreshToken = "refreshToken",
  totalSent = "totalSent",
  totalOpen = "totalOpen",
  areaSent = "areaSent",
  areaOpen = "areaOpen",
  setError = "setError",
  relog = "relog"
}

const initialState = {
  token: null,
  refreshToken: null,
  user: null,
  totalSent: null,
  totalOpen: null,
  areaSent: null,
  areaOpen: null,
  loginError: false,
  loading: false,
  error: "",
};

// export enum TokenValidation {
//   Valid = 'valid',
//   Invalid = 'invalid',
//   Exp = 'exp'
// }

// const initializer = (): IUserState => {
//   if (checkTokenValid() === TokenValidation.Valid) {
//     const tokenStr = window.localStorage.getItem('token')
//     const storedUser = window.localStorage.getItem('user') ?? ''
//     return {
//       token: tokenStr,
//       user: storedUser ? JSON.parse(storedUser) : null
//     }
//   } else {
//     return initialState
//   }
// }

// export const getValidToken = (): string | false | null => {
//   const tokenStr = window.localStorage.getItem('token')
//   if (checkTokenValid(tokenStr) === TokenValidation.Valid) {
//     return tokenStr
//   }
//   return false
// }

// const checkTokenValid = (tokenStr?: string | null): TokenValidation => {
//   tokenStr = tokenStr ?? window.localStorage.getItem('token')
//   let token: IToken | undefined
//   if (tokenStr) {
//     token = jwtdecode(tokenStr)
//   }
//   if (!token) return TokenValidation.Invalid
//   if (token.exp < Date.now() / 1000) return TokenValidation.Exp
//   return TokenValidation.Valid
// }

// interface IToken {
//   exp: number
//   iat: number
//   user: User
// }

const reducer = (state: IUserState, action: IAction): IUserState => {
  const newState = { ...state };
  switch (action.type) {
    case UserActions.setError:
      newState.error = action.payload;
      break;
    case UserActions.loginError:
    case UserActions.loading:
    case UserActions.totalSent:
      newState.totalSent = action.payload;
      break;
    case UserActions.totalOpen:
      newState.totalOpen = action.payload;
      break;
    case UserActions.areaOpen:
      newState.areaOpen = action.payload;
      break;
    case UserActions.areaSent:
      newState.areaSent = action.payload;
      break;
    case UserActions.user:
      newState.error = "";
      newState.user = action.payload;
      console.log("action.payload user: ", action.payload);
      break;
    case UserActions.token:
      newState.token = action.payload;
      break;
    
    default:
      return state;
  }

  return newState;
};

export const UserStateContext: any = React.createContext({});
export const UserDispatchContext: any = React.createContext({});
export const UserStateRefContext: any = React.createContext({});


export const useUserState = (): IUserState => useContext(UserStateContext);
export const useUserDispatch = (): Dispatch<IAction> =>
  useContext(UserDispatchContext);
export const useUserStateRef = (): React.MutableRefObject<IUserState> =>
  useContext(UserStateRefContext);

const middleware = [
  logger("User"),
  userLogin,
  userLogout,
  loginFromStorage,
  relog,
];

export const UserProvider: React.FC = ({ children }) => {
  const [state, dispatch, userStateRef] = useEnhancedReducer(
    reducer,
    initialState,
    (state) => ({ ...state }),
    middleware
  );
  const statsDispatch = useStatsDispatch();
  const { getAllStats } = useApi();

  const getAll = async () => {
    let res = await getAllStats();
    if (res?.data) {
      statsDispatch({ type: StatsActions.totalRead, payload: res.data.numRead});
      statsDispatch({ type: StatsActions.totalSent, payload: res.data.numSent});
    }
  }

  useEffect(() => {
    getAll()
  }, [])

  // const { getUser, getHomeData, getClient } = useApi();

  // const getFullUser = async () => {
  //   if (state?.user?._id) {
  //     const resp = await getUser(state?.user?._id);
  //     if (resp?.Success) {
  //       console.log("resp getuser: ", resp?.data);
  //       dispatch({ type: UserActions.user, payload: resp.data });
  //     }
  //   }

  //   if(state?.user?.clientId) {
  //     const resp = await getClient(state?.user?.clientId);
  //     if(resp?.Success) {
  //       console.log('client from clientid: ', resp.data)
  //       dispatch({ type: UserActions.client, payload: (resp.data as Models.Client) })
  //     }
  //   }
    
  //   const resp = await getHomeData();
  //   if (resp?.Success) {
  //     // Set Locations context
  //     dispatch({ type: UserActions.locations, payload: (resp.data as Models.HomePageResponse).locations });

  //     dispatch({ type: DocumentActions.setDocuments, payload: (resp.data as Models.HomePageResponse).documents })
  //     // Set Client context
  //     // dispatch({ type: UserActions.client, payload: (resp.data as HomePageResponse).client })

  //     // Set current location to the first location returned
  //     dispatch({ type: UserActions.currentLocation, payload: (resp.data as Models.HomePageResponse).locations[0] })
  //   }
    
  // };

  // const loginAttempt = useRef(false);
  // if (!loginAttempt.current && !state.user) {
  //   dispatch({ type: UserActions.loginFromStorage });
  //   // getFullUser();
  //   loginAttempt.current = true;
  // }
  // // HomePageResponse
  // useEffect(() => {
  //   getFullUser();
  // }, [state?.token]);

  //// Grab All Stats
  


  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        <UserStateRefContext.Provider value={userStateRef}>
          {children}
        </UserStateRefContext.Provider>
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
};



// export const usePermissionCheck = () => {
//   const userState = useUserState()

//   const runCheck = useCallback(
//     (permission: Permission, ffl?: Models.FFLPage, fflLic?: Models.FFLLicense) => {
//       const u = userState.user
//       switch (permission) {
//         case Permission.manageFFL:
//           if (ffl) {
//             // is specific ffl manager
//             return (u?.role === Enums.Role.FFLManager || u?.role === Enums.Role.FFLAssociate) && u._ffl && u._ffl === ffl?._id
//           } else {
//             // is ANY ffl manager
//             return u?.role === Enums.Role.FFLManager || u?.role === Enums.Role.FFLAssociate
//           }
//         default:
//           return false
//       }
//     },
//     [userState.user]
//   )
//   return runCheck
// }

// export const useCheckTokenValid = (): [() => TokenValidation, (valid?: TokenValidation | undefined) => void] => {
//   const userDispatch = useUserDispatch()
//   const userStateRef = useUserStateRef()

//   const handleLogCheck = useCallback(
//     (valid?: TokenValidation) => {
//       if (!valid) {
//         valid = checkTokenValid()
//       }
//       if (userStateRef.current.token) {
//         if (valid === TokenValidation.Exp) {
//           userDispatch({ type: UserActions.login })
//         }
//         if (valid === TokenValidation.Invalid) {
//           userDispatch({ type: UserActions.logout })
//         }
//       }
//     },
//     [userDispatch, userStateRef]
//   )
//   return [checkTokenValid, handleLogCheck]
// }
